import dayjs from 'dayjs';
import { parseDateGeneralStringFormat } from '../../../log-utils';
import { getCurrentDate } from './ChartDateForrmatter';
import {
  ChartDalabels,
  ChartDataSets,
  ChartForrmaterData,
} from '../interface/ChartInterface';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

export const secondsToHMS = (seconds: number) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const totalMinutes = hours * 60 + minutes;
  return {
    totalMinutes,
    seconds: remainingSeconds,
  };
};

export const chartDataLabelCreate = (
  cellWidth: number,
  timestamp: number,
  duration: number,
  y: number,
  bgHeight: number,
  driverLogDate: string,
  endDay: number,
  timeZone: string,
  id: string,
) => {
  const t = parseDateGeneralStringFormat(timestamp);
  const timeOnly = dayjs(t, 'MM/DD/YYYY hh:mm:ss A')
    .tz(timeZone)
    .format('HH:mm:ss');
  const [hours, minutes, seconds] = timeOnly.split(':').map(parseFloat);
  const totalMinutes = hours * 60 + minutes + seconds / 60;
  if (duration != 0) {
    const dur = duration - timestamp;
    const width = secondsToHMS(Math.floor(dur));
    const label = {
      left: (totalMinutes / 60) * cellWidth,
      width: (width.totalMinutes / 60) * cellWidth,
      top: (bgHeight / 5) * (4 - y),
      hour: Math.floor(width.totalMinutes / 60),
      minute: Math.floor(width.totalMinutes % 60),
      seconds: width.seconds,
      id,
      y,
    };
    return label;
  } else {
    const date = getCurrentDate(timeZone);
    if (driverLogDate === date.formattedDate) {
      const width = secondsToHMS(Math.floor(date.timestamp / 1000) - timestamp);
      const label = {
        left: (totalMinutes / 60) * cellWidth,
        width: (width.totalMinutes / 60) * cellWidth,
        top: (bgHeight / 5) * (4 - y),
        hour: Math.floor(width.totalMinutes / 60),
        minute: Math.floor(width.totalMinutes % 60),
        seconds: width.seconds,
        id,
        y,
      };
      return label;
    } else {
      const width = secondsToHMS(Math.floor(endDay / 1000) - timestamp);
      const label = {
        left: (totalMinutes / 60) * cellWidth,
        width: (width.totalMinutes / 60) * cellWidth,
        top: (bgHeight / 5) * (4 - y),
        hour: Math.floor(width.totalMinutes / 60),
        minute: Math.floor(width.totalMinutes % 60),
        seconds: width.seconds,
        id,
        y,
      };
      return label;
    }
  }
};
export const chartDataLables = (
  cellWidth: number,
  bgHeight: number,
  chartFormmatedData: ChartForrmaterData[],
  setDataLabels: React.Dispatch<React.SetStateAction<ChartDalabels[]>>,
  driverLogDate: string,
  endDay: number,
  startDay: number,
  timeZone: string,
) => {
  for (let index = 0; index < chartFormmatedData.length; index++) {
    const element = chartFormmatedData[index];
    const nextItem = index + 1 < chartFormmatedData.length;
    if (element.x < startDay) {
      const datalabel = chartDataLabelCreate(
        cellWidth,
        startDay / 1000,
        nextItem ? chartFormmatedData[index + 1].x / 1000 : 0,
        element.y,
        bgHeight,
        driverLogDate,
        endDay,
        timeZone,
        element.id,
      );
      setDataLabels((previtems) => [...previtems, datalabel]);
    } else {
      const datalabel = chartDataLabelCreate(
        cellWidth,
        element.x / 1000,
        nextItem ? chartFormmatedData[index + 1].x / 1000 : 0,
        element.y,
        bgHeight,
        driverLogDate,
        endDay,
        timeZone,
        element.id,
      );
      setDataLabels((previtems) => [...previtems, datalabel]);
    }
  }
};
