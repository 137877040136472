import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  updateDeviceReq,
  getDeviceReq,
  setCurrentDeviceCarrier,
} from '../../../actions/device';
import { CARRIER_SELECT_DISABLED } from '../../common/doubleinput/utils';

import { Row, Col, Form, Button, Input, Spin } from 'antd';
import { CommonInput } from '../../common/inputs';
import { deviceForm } from './device-form';
import { InputType } from '../../../constants/inputs';
import { PAGE_STATUS } from './constant';

import { usePermissions } from '../../../hooks/usePermissions';
import { AllPermissionsType } from '../role/constant';
import { NoPermission } from '../../common/NoPermission';
import { cleanObject } from '../../../utils/cleanObject';
import MainContainer from '../../layout/MainContainer';

export const DevicePage = () => {
  const [form] = Form.useForm();
  const params = useParams();
  const [search, setSearch] = useSearchParams();
  const [state, setStateValue] = React.useState(search.get('state'));
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = useState({
    identificator: '',
    type: null,
    mac_address: '',
    model: '',
    fuel_type: null,
    carrier: null,
    status: null,
    serial_number: '',
    firmware: '',
  });
  const { loading, device, currentCarrier } = useSelector(
    (state: any) => state.device,
  );
  const { loading: carrierLoading, carrierList } = useSelector(
    (state: any) => state.carrier,
  );

  const [fields, setFields] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (device?.carrier?.id) {
      const foundCarrier = carrierList.find(
        (carrier: any) => carrier?.id === device?.carrier?.id,
      );

      dispatch(
        setCurrentDeviceCarrier({ ...foundCarrier, defaultSavedCarrier: true }),
      );
    }
  }, [carrierList, device]);

  React.useEffect(() => {
    setStateValue(search.get('state'));
  }, [search]);

  useEffect(() => {
    form.setFieldsValue({
      ...form.getFieldsValue(),
      ...(!currentCarrier.defaultSavedCarrier ? currentCarrier?.settings : {}),
    });
  }, [currentCarrier]);

  useEffect(() => {
    if (params.deviceId) {
      dispatch(
        getDeviceReq({
          deviceId: params.deviceId,
          queryParams: {
            with: ['terminal', 'group', 'carrier', 'documents'],
          },
        }),
      );
    }
  }, []);

  const handleSubmit = async (values: any) => {
    const value = {
      ...values,
      type: 1,
      identificator: values.serial_number,
    };
    const data = cleanObject(value);

    dispatch(
      updateDeviceReq({
        values: data,
        deviceId: params.deviceId,
      }),
    );
  };

  React.useEffect(() => {
    const allTypes: any = {
      1: ' ELD',
    };
    const type = allTypes[device.type];
    setInitialValues({
      ...initialValues,
      ...device,
      carrier: device?.carrier?.id,
      license_plate: +device?.license_plate,
      type,
    });
    form.setFieldsValue({
      ...initialValues,
      ...device,
      carrier: device?.carrier?.id,
      license_plate: +device?.license_plate,
      type,
    });
  }, [device]);

  const { checkPermission } = usePermissions();

  return (
    <>
      {checkPermission(AllPermissionsType.DEVICE_SHOW) ? (
        <MainContainer half>
          <Row
            style={{
              height: '100%',
            }}
          >
            {loading ? (
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  minHeight: 600,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Spin />
              </div>
            ) : (
              <Col span={24}>
                <Form
                  form={form}
                  name="test"
                  onFinish={handleSubmit}
                  initialValues={initialValues}
                >
                  {deviceForm({}).map((fieldCurrent: any, i: number) => {
                    const field = {
                      ...fieldCurrent,
                      disabled:
                        state === PAGE_STATUS.VIEW ||
                        !checkPermission(AllPermissionsType.DEVICE_EDIT),
                      isReadonlyCarrier: true,
                      isIdentificatorDisabled: true,
                    };

                    if (CARRIER_SELECT_DISABLED.includes(field.type)) {
                      return (
                        <CommonInput
                          currentIndex={currentIndex}
                          fields={fields}
                          key={i}
                          setCurrentIndex={setCurrentIndex}
                          {...field}
                          form={form}
                          isReadonlyCarrier={true}
                        />
                      );
                    }

                    if (field.type === InputType.ADD_DYNAMIC) {
                      return (
                        <CommonInput
                          currentIndex={currentIndex}
                          fields={fields}
                          key={i}
                          setCurrentIndex={setCurrentIndex}
                          {...field}
                          form={form}
                        />
                      );
                    }
                    return (
                      <CommonInput
                        key={i}
                        {...field}
                        form={form}
                        showDocsList={true}
                      />
                    );
                  })}
                  <Form.Item style={{ width: '100%', display: 'flex' }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="orange"
                      style={{ width: '65px', marginRight: 12 }}
                      disabled={
                        state === PAGE_STATUS.VIEW ||
                        !checkPermission(AllPermissionsType.DEVICE_EDIT)
                      }
                    >
                      Save
                    </Button>
                    <Button
                      className="grey"
                      style={{ width: '85px', marginRight: 12 }}
                      onClick={() => {
                        form.setFieldsValue(initialValues);
                      }}
                      disabled={
                        state === PAGE_STATUS.VIEW ||
                        !checkPermission(AllPermissionsType.DEVICE_EDIT)
                      }
                    >
                      Cancel
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            )}
          </Row>
        </MainContainer>
      ) : (
        <NoPermission />
      )}
    </>
  );
};
