import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Row, Col, Select } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useTableParams } from '../../../hooks/useTableParams';
import dayjs from 'dayjs';
import { defaultTo } from 'lodash';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { usePermissions } from '../../../hooks/usePermissions';
import { AllPermissionsType } from '../role/constant';
import { NoPermission } from '../../common/NoPermission';
import { parseDateGeneralStringFormat } from '../driver_log/log-utils';
import { getEventLabel } from '../driver_log/log-utils';
import { getOriginLabel } from '../driver_log/log-utils';
import MainContainer from '../../layout/MainContainer';
import MainHeader from '../../header/MainHeader';
import { assignUnLogReq, getUnLogReq } from '../../../actions/undifiend';

dayjs.extend(customParseFormat);

export const LogListUnidentified: React.FC = () => {
  const dispatch = useDispatch();
  const { handleTableChange, tableParams, rowSelection } = useTableParams({});
  const logs = useSelector((state: any) => state.log.logList);
  const driverList = useSelector((state: any) => state.driver.driverList);
  const count = useSelector((state: any) => state.log.count);
  const loading = useSelector((state: any) => state.log.loading);
  const [logDateFilter, setLogDateFilter] = React.useState<any>('');
  const [selectedDriverId, setSelectedDriverId] = useState('');
  const undifiendList = useSelector(
    (state: any) => state.undefined.undefinedList,
  );
  const undifiendCount = useSelector((state: any) => state.undefined.count);

  React.useEffect(() => {
    dispatch(
      getUnLogReq({
        queryParams: {
          with: ['unit', 'carrier', 'driver'],
          page: 1,
          limit: 20,
        },
      }),
    );
  }, []);

  const seen = new Set<number>();
  const filtredCarriers = undifiendList.filter((item: any) => {
    if (seen.has(item.carrier?.id)) {
      return false;
    } else {
      seen.add(item.carrier?.id);
      return true;
    }
  });
  const columns: ColumnsType<any> = [
    Table.SELECTION_COLUMN,
    {
      title: 'Date & Time',
      key: 'identificator_log',
      dataIndex: 'identificator',
      render: (name, record, index) => {
        return (
          <div style={{ marginLeft: 10 }}>{`${parseDateGeneralStringFormat(
            record?.timestamp,
          )}`}</div>
        );
      },
      width: '15%',
      ellipsis: true,
    },
    {
      title: 'Carrier',
      key: 'carrier',
      dataIndex: 'carrier',
      render: (name, record, index) => {
        return <div>{record.carrier?.name}</div>;
      },
      width: '12%',
      ellipsis: true,
      filters: filtredCarriers.map((st: any) => {
        return {
          text: st.carrier?.name,
          value: st.carrier?.id,
        };
      }),

      filterSearch: true,
    },
    {
      title: 'Event',
      dataIndex: 'event',
      key: 'event',
      width: '10%',
      ellipsis: true,
      render: (value, record, index) => {
        return (
          <div className="ubuntu">
            {getEventLabel(record?.event_type, record?.event_code)}
          </div>
        );
      },
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
      width: '10%',
      ellipsis: true,
      render: (value, record, index) => {
        return (
          <div className="ubuntu" style={{ cursor: 'pointer' }}>
            {record?.location ? `${record?.location}` : ''}
          </div>
        );
      },
    },

    {
      title: 'Vehicle',
      dataIndex: 'vehicle',
      key: 'vehicle',
      width: '9%',
      ellipsis: true,
      render: (value, record, index) => {
        return (
          <div className="ubuntu" style={{ cursor: 'pointer' }}>
            {record?.unit?.vehicle?.identificator}
          </div>
        );
      },
    },

    {
      title: 'Odometer',
      dataIndex: 'total_miles',
      key: 'total_miles',
      width: '7%',
      ellipsis: true,
      render: (value, record, index) => {
        return (
          <div className="ubuntu" style={{ cursor: 'pointer' }}>
            {record?.total_miles}
          </div>
        );
      },
    },
    {
      title: 'EH',
      dataIndex: 'total_hours',
      key: 'total_hours',
      width: '5%',
      ellipsis: true,
      render: (value, record, index) => {
        return (
          <div className="ubuntu" style={{ cursor: 'pointer' }}>
            {record?.total_hours?.toFixed(1)}
          </div>
        );
      },
    },
    {
      title: 'Select Driver',
      dataIndex: 'progress',
      key: 'progress',
      width: '18%',
      ellipsis: true,
      render: (value, record, index) => {
        const defaultValue = record.driver?.id;
        return (
          <div>
            <Select
              showSearch
              optionFilterProp="children"
              style={{ width: 220 }}
              defaultValue={defaultValue}
              onChange={(e) => setSelectedDriverId(e)}
            >
              {driverList
                .filter(
                  (driver: any) => driver.carrier.id === record.carrier.id,
                )
                .filter((driver: any) => driver.status === 1)
                .map((item: any, i: number) => {
                  return (
                    <Select.Option
                      key={i}
                      value={item.id}
                      style={{ backgroundColor: item.color }}
                    >
                      {item?.first_name} {item?.last_name}
                    </Select.Option>
                  );
                })}
            </Select>
          </div>
        );
      },
    },
    {
      title: 'Assign',
      dataIndex: 'edit',
      key: 'edit',
      width: '8%',
      ellipsis: true,
      render: (value, record, index) => {
        const asignClick = () => {
          // alert(selectedDriverId);
          dispatch(
            assignUnLogReq({
              body: {
                driver: selectedDriverId,
                events: [record.id],
              },
            }),
          );
        };
        return (
          <div className="ubuntu" style={{ display: 'flex' }}>
            <button onClick={asignClick} className="roll-back-button">
              Assign
            </button>
          </div>
        );
      },
    },
  ];

  const { checkPermission } = usePermissions();

  const getPage = (page: number, limit: number) => {
    dispatch(
      getUnLogReq({
        queryParams: {
          with: ['unit', 'carrier', 'driver'],
          page,
          limit,
        },
      }),
    );
  };
  return (
    <>
      {checkPermission(AllPermissionsType.VEHICLE_LIST) ? (
        <>
          <MainContainer>
            <Row>
              <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
                <MainHeader titles={[{ text: 'Unidentified Logs' }]} />
                <div style={{ marginTop: 10 }}>
                  {!!logDateFilter[0]
                    ? `Date rage: ${logDateFilter[0]} - ${logDateFilter[1]}`
                    : null}
                </div>
              </Col>
            </Row>
            <div style={{ width: '100%' }} className="logs-table">
              <Table
                columns={columns}
                rowKey={(record) => record.id}
                dataSource={undifiendList}
                pagination={{
                  ...tableParams.pagination,
                  position: ['bottomCenter'],
                  total: defaultTo(undifiendCount, undifiendCount),
                }}
                loading={loading}
                onChange={(e) => {
                  handleTableChange(e, {}, {});
                  getPage(
                    e.current ? e.current : 1,
                    e.pageSize ? e.pageSize : 20,
                  );
                }}
                rowSelection={{ ...rowSelection, columnWidth: 10 }}
                className="table-custom"
              />
            </div>
          </MainContainer>
        </>
      ) : (
        <NoPermission />
      )}
    </>
  );
};
