import { PieChart } from '@mui/x-charts/PieChart';
import { PieSeriesType, PieValueType } from '@mui/x-charts/models';
import { MakeOptional } from '@mui/x-charts/models/helpers';
import styles from './LogDashboardPanelItem.module.css';
interface ILogDashboardPanelItem {
  series?: MakeOptional<
    PieSeriesType<MakeOptional<PieValueType, 'id'>>,
    'type'
  >[];
  text: string;
  time?: string;
  certificated?: boolean;
}

const LogDashboardPanelItem = ({
  series,
  text,
  time,
  certificated,
}: ILogDashboardPanelItem) => {
  return (
    <div className={styles.item}>
      {series && (
        <div>
          <PieChart
            series={series}
            width={34}
            height={30}
            colors={['#FFAB00', '#D8E3F3']}
          />
        </div>
      )}
      <div>
        <div style={{ fontWeight: 'bold', marginBottom: 5, color: '#141029' }}>
          {text}
        </div>
        <div
          style={{
            color:
              certificated === false
                ? 'rgb(253, 55, 31)'
                : 'rgba(126, 126, 154, 1)',
          }}
        >
          {certificated ? (
            <p
              style={{
                margin: 0,
                color: 'rgba(105, 206, 70, 1)',
              }}
            >
              {time === 'DVIR' ? 'Yes' : 'Certificated'}
            </p>
          ) : (
            time
          )}
        </div>
      </div>
    </div>
  );
};

export default LogDashboardPanelItem;
