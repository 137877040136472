import React, { useRef, useState } from 'react';
import AllIcons from '../icons/AllIcons';
import { ChartPointsData } from '../interface/ChartInterface';
import styles from '../ChartTestV.module.css';
import ChartTooltip from './ChartTooltip';
interface IChartPoints {
  points: ChartPointsData[];
  bgHeight: number;
  choosed: string;
  setChoosed: React.Dispatch<React.SetStateAction<string>>;
}

const ChartPoints = ({
  points,
  bgHeight,
  choosed,
  setChoosed,
}: IChartPoints) => {
  const divRefs = useRef<HTMLDivElement[]>([]);
  const [tooltipVisible, setTooltipVisible] = useState(points.map(() => false));

  const handleMouseEnter = (index: number) => {
    setTooltipVisible((prev) => {
      const newState = [...prev];
      newState[index] = true;
      return newState;
    });
  };

  const handleMouseLeave = (index: number) => {
    setTooltipVisible((prev) => {
      const newState = [...prev];
      newState[index] = false;
      return newState;
    });
  };
  const handleFocus = (index: number) => {
    divRefs.current[index].focus();
  };
  const handleClick = (id: string) => {
    setChoosed(id);
  };
  const handleBlur = () => {
    setChoosed('');
  };
  return (
    <>
      {points.map((point, index) => (
        <>
          <div
            key={index}
            ref={(el) => {
              if (el) divRefs.current[index] = el;
            }}
            onClick={() => handleClick(point.id)}
            onBlur={handleBlur}
            onFocus={() => handleFocus(index)}
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={() => handleMouseLeave(index)}
            className={styles.point}
            style={{
              left: point.x - 10,
              bottom: point.postion === 'bottom' ? 16 : undefined,
              top: point.postion === 'top' ? -35 : undefined,
            }}
            tabIndex={0}
          >
            {point.img && <AllIcons type={point.img} color={point.color} />}
            <div
              className={styles.pointLine}
              style={{
                height: (bgHeight / 5) * 4,
                borderColor: point.color,
                borderStyle: point.borderStyle,
                borderWidth: 1,
                bottom: point.postion === 'bottom' ? 24 : undefined,
                top: point.postion === 'top' ? 35 : undefined,
              }}
            />
          </div>
          <ChartTooltip
            position={point.postion}
            left={point.x}
            text={point.tooltipText}
            visible={tooltipVisible[index]}
          />
        </>
      ))}
    </>
  );
};

export default ChartPoints;
