import { Row, Col } from 'antd';

import './log-top-panel.scss';
import LogDashboardPanelItem from './LogDashboardPanelItem';
import { PieSeriesType, PieValueType } from '@mui/x-charts/models';
import { MakeOptional } from '@mui/x-charts/models/helpers';
interface ItemsLogPanel {
  text: string;
  time: string;
  series?: MakeOptional<
    PieSeriesType<MakeOptional<PieValueType, 'id'>>,
    'type'
  >[];
  certificated?: boolean;
}

export const timeFormat = (second: number) => {
  const days = Math.floor(second / (3600 * 24));
  const hours = Math.floor((second % (3600 * 24)) / 3600);
  const minutes = Math.floor((second % 3600) / 60);
  const time = {
    days,
    hours,
    minutes,
  };
  return time;
};

export const LogDashboardPanel = () => {
  const items: ItemsLogPanel[] = [
    {
      text: 'Break In',
      time: '6h 40m',
      series: [
        {
          data: [
            {
              value: 90,
            },
            {
              value: 10,
            },
          ],
          innerRadius: 6,
          outerRadius: 15,
          paddingAngle: 1,
          cornerRadius: 2,
          cx: 10,
        },
      ],
    },
    {
      text: 'Drive',
      time: '9h 40m',
      series: [
        {
          data: [
            {
              value: 15,
            },
            {
              value: 85,
            },
          ],
          innerRadius: 6,
          outerRadius: 15,
          paddingAngle: 1,
          cornerRadius: 2,
          cx: 10,
        },
      ],
    },
    {
      text: 'Shift',
      time: '6h 40m',
      series: [
        {
          data: [
            {
              value: 50,
            },
            {
              value: 50,
            },
          ],
          innerRadius: 6,
          outerRadius: 15,
          paddingAngle: 1,
          cornerRadius: 2,
          cx: 10,
        },
      ],
    },
    {
      text: 'Cycle',
      time: '44h 40m',
      series: [
        {
          data: [
            {
              value: 70,
            },
            {
              value: 30,
            },
          ],
          innerRadius: 6,
          outerRadius: 15,
          paddingAngle: 1,
          cornerRadius: 2,
          cx: 10,
        },
      ],
    },
    {
      text: 'Previos',
      time: '44h 40m',
      series: [
        {
          data: [
            {
              value: 80,
            },
            {
              value: 20,
            },
          ],
          innerRadius: 6,
          outerRadius: 15,
          paddingAngle: 1,
          cornerRadius: 2,
          cx: 10,
        },
      ],
    },
  ];
  const items2: ItemsLogPanel[] = [
    {
      text: 'Break In',
      time: '6h 40m',
      series: [
        {
          data: [
            {
              value: 90,
            },
            {
              value: 10,
            },
          ],
          innerRadius: 6,
          outerRadius: 15,
          paddingAngle: 1,
          cornerRadius: 2,
          cx: 10,
        },
      ],
    },
    {
      text: 'Drive',
      time: '9h 40m',
      series: [
        {
          data: [
            {
              value: 15,
            },
            {
              value: 85,
            },
          ],
          innerRadius: 6,
          outerRadius: 15,
          paddingAngle: 1,
          cornerRadius: 2,
          cx: 10,
        },
      ],
    },
    {
      text: 'Shift',
      time: '6h 40m',
      series: [
        {
          data: [
            {
              value: 50,
            },
            {
              value: 50,
            },
          ],
          innerRadius: 6,
          outerRadius: 15,
          paddingAngle: 1,
          cornerRadius: 2,
          cx: 10,
        },
      ],
    },
    {
      text: 'Cycle',
      time: '44h 40m',
      series: [
        {
          data: [
            {
              value: 70,
            },
            {
              value: 30,
            },
          ],
          innerRadius: 6,
          outerRadius: 15,
          paddingAngle: 1,
          cornerRadius: 2,
          cx: 10,
        },
      ],
    },
  ];
  return (
    <Row
      style={{
        width: '100%',
        paddingTop: 10,
        gap: 15,
        justifyContent: 'space-between',
      }}
    >
      <Col>
        <p
          style={{
            color: '#374957',
            fontWeight: 700,
            paddingLeft: 10,
          }}
          className="ubuntu"
        >
          Active
        </p>
        <Row
          style={{
            width: '100%',
            justifyContent: 'space-between',
            marginTop: 10,
          }}
        >
          <Row style={{ gap: 5 }}>
            {items.slice(0, 5).map((i) => (
              <>
                <LogDashboardPanelItem
                  series={i.series}
                  text={i.text}
                  time={i.time}
                  certificated={i.certificated}
                />
              </>
            ))}
          </Row>
        </Row>
      </Col>
      <Col>
        <p
          style={{
            color: '#374957',
            fontWeight: 700,
            paddingLeft: 10,
          }}
          className="ubuntu"
        >
          Suggested
        </p>
        <Row style={{ width: '100%', gap: 5, marginTop: 10 }}>
          {items2.map((i) => (
            <>
              <LogDashboardPanelItem
                series={i.series}
                text={i.text}
                time={i.time}
                certificated={i.certificated}
              />
            </>
          ))}
        </Row>
      </Col>
    </Row>
  );
};
