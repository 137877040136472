import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getCarriersListReq } from '../../../actions/carrier';

import { Row, Col, Form, Button, Input, Spin } from 'antd';
import { CommonInput } from '../../common/inputs';
import { deviceForm } from './device-form';
import { InputType } from '../../../constants/inputs';

import {
  createDeviceReq,
  setCurrentDeviceCarrier,
} from '../../../actions/device';

import { usePermissions } from '../../../hooks/usePermissions';
import { AllPermissionsType } from '../role/constant';
import { NoPermission } from '../../common/NoPermission';
import { cleanObject } from '../../../utils/cleanObject';
import MainContainer from '../../layout/MainContainer';

export const DeviceCreatePage = () => {
  const [form] = Form.useForm();
  const params = useParams();
  const dispatch = useDispatch();
  const { loading, device, currentCarrier } = useSelector(
    (state: any) => state.device,
  );
  const navigate = useNavigate();
  const [fields, setFields] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const [initialValues, setInitialValues] = useState({
    name: '',
    model: 'T229LA',
    carrier: null,
    status: 1,
    notes: null,
    mac_address: null,
    serial_number: '',
    type: 'ELD',
    firmware: '',
    eld_reg_id: 'NV33',
    eld_identifier: 'CORELD',
    identificator: '',
  });

  React.useEffect(() => {
    dispatch(
      getCarriersListReq({
        queryParams: {
          with: ['settings', 'terminals', 'driver_groups'],
        },
      }),
    );
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      ...form.getFieldsValue(),
    });
  }, [currentCarrier]);

  const handleSubmit = (values: any) => {
    const value = {
      ...values,
      type: 1,
      identificator: values.identificator,
    };
    const data = cleanObject(value);
    dispatch(
      createDeviceReq({
        values: data,
        onSuccess: () => {
          form.setFieldsValue(initialValues);
          setCurrentDeviceCarrier({});
          navigate(-1);
        },
      }),
    );
  };

  const { checkPermission } = usePermissions();
  return (
    <>
      {checkPermission(AllPermissionsType.DEVICE_CREATE) ? (
        <MainContainer half>
          <Row
            style={{
              height: '100%',
            }}
          >
            {loading ? (
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  minHeight: 600,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Spin />
              </div>
            ) : (
              <Col span={24}>
                <Form
                  form={form}
                  name="test"
                  onFinish={handleSubmit}
                  initialValues={initialValues}
                >
                  {deviceForm({ create: true }).map((field: any, i: number) => {
                    if (field.type === InputType.ADD_DYNAMIC) {
                      return (
                        <CommonInput
                          currentIndex={currentIndex}
                          fields={fields}
                          key={i}
                          setCurrentIndex={setCurrentIndex}
                          {...field}
                          form={form}
                        />
                      );
                    }
                    return <CommonInput key={i} {...field} form={form} />;
                  })}
                  <Form.Item style={{ width: '100%', display: 'flex' }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="orange"
                      style={{ width: '85px', marginRight: 12 }}
                    >
                      Submit
                    </Button>
                    <Button
                      className="grey"
                      style={{ width: '85px', marginRight: 12 }}
                      onClick={() => {
                        form.setFieldsValue(initialValues);
                      }}
                    >
                      Cancel
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            )}
          </Row>
        </MainContainer>
      ) : (
        <NoPermission />
      )}
    </>
  );
};
