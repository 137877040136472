import { Button, Form, notification } from 'antd';
import { InputPageTitle } from '../../common/doubleinput/InputPageTitle';
import { DOT, DOTFields } from './dot.constans';
import InputsBlock, { IInput } from './InputsBlock';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { usePermissions } from '../../../hooks/usePermissions';
import { AllPermissionsType, PAGE_STATUS } from '../role/constant';
import { NoPermission } from '../../common/NoPermission';
import RecordDotErrorsModal from './RecordDotErrorsModal';
import { useParams } from 'react-router-dom';
import MainContainer from '../../layout/MainContainer';
import MainHeader from '../../header/MainHeader';
import { API } from '../../../api/API';

notification.config({
  placement: 'topRight',
  bottom: 50,
  duration: 5,
});

const RecordDot = () => {
  const [form] = Form.useForm();
  const params = useParams();
  const initialValues = Object.fromEntries(
    Object.values(DOT).map((value) => [value, '']),
  );
  const handleSubmit = async (values: any) => {};

  const { checkPermission } = usePermissions();
  const [violationsValue, setViolationsValue] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errorFieldValue, setErrorFieldValue] = useState('');
  const [inspectionsFileds, setInspectionsFileds] = useState<IInput[]>([
    {
      inputType: 'select',
      type: 'single',
      options: [
        {
          key: 1,
          value: 'Yes',
        },
        {
          key: 0,
          value: 'No',
        },
      ],
      name: DOT.violations,
      label: 'Violations Registred',
      placeholder: 'Enter Violations',
      hasFeedback: true,
      onChange: violationsChange,
      title: 'Violations Registred *',
      span: 24,
      width: '100%',
    },
  ]);
  const addClick = () => {
    if (violationsValue === 1) {
      setInspectionsFileds((prevState) => [
        ...prevState,
        {
          inputType: 'text',
          type: 'double',
          name: DOT.inspectionCode,
          label: 'Code',
          placeholder: 'Enter Code',
          hasFeedback: true,
          title: 'Code',
          span: 24,
          width: '100%',
        },
        {
          inputType: 'text',
          type: 'double',
          name: DOT.inspectionCode1,
          label: 'Code',
          placeholder: 'Enter Code',
          hasFeedback: true,
          title: 'Code',
          span: 24,
          width: '100%',
        },
      ]);
    } else {
      return notification.error({
        message: 'Violations Registred field must not be empty',
      });
    }
  };
  function violationsChange(value: number) {
    setViolationsValue(value);
    if (value === 1) {
      setInspectionsFileds((prevState) => [
        ...prevState,
        {
          inputType: 'text',
          type: 'double',
          name: DOT.inspectionCode,
          label: 'Code',
          placeholder: 'Enter Code',
          hasFeedback: true,
          title: 'Code',
          span: 24,
          width: '100%',
        },
        {
          inputType: 'text',
          type: 'double',
          name: DOT.inspectionCode1,
          label: 'Code',
          placeholder: 'Enter Code',
          hasFeedback: true,
          title: 'Code',
          span: 24,
          width: '100%',
        },
      ]);
    } else {
      setInspectionsFileds([
        {
          inputType: 'select',
          type: 'single',
          options: [
            {
              key: 1,
              value: 'Yes',
            },
            {
              key: 0,
              value: 'No',
            },
          ],
          name: DOT.violations,
          label: 'Violations Registred',
          placeholder: 'Enter Violations',
          hasFeedback: true,
          onChange: violationsChange,
          title: 'Violations Registred *',
          span: 24,
          width: '100%',
        },
      ]);
    }
  }

  const openModalClick = () => {
    if (errorFieldValue != 'Accepted') {
      setIsModalOpen(true);
    }
  };
  const { getSingleDOTRepost } = API;
  const init = async () => {
    if (params.dotId) {
      const response = await getSingleDOTRepost(params.dotId);

      const user =
        !!response.user === true
          ? response.user.first_name + ' ' + response.user.last_name
          : response.driver.first_name + ' ' + response.driver.last_name;
      form.setFieldsValue({
        driver: response.driver.first_name + ' ' + response.driver.last_name,
        period: `${response.date_from} - ${response.date_to}`,
        carrier: response.carrier.name,
        origin: response.origin,
        code: response.code,
        createTime: dayjs(response.created_at).format('MM/DD/YYYY hh:mm:ss'),
        processedTime: response.processed_at,
        location: response.location,
        method: response.method,
        status: response.status_value,
        submissionId: response.submission,
        user,
        notice: response.notice,
        investigationCode: '',
        inspectionCode: '',
        inspectionCode1: '',
      });
      if (params?.dotId === '0') {
        setErrorFieldValue('Warning');
      } else {
        setErrorFieldValue('Accepted');
      }
    }
  };
  useEffect(() => {
    init();
  }, []);

  return (
    <>
      {checkPermission(AllPermissionsType.COMPANY_SHOW) ? (
        <MainContainer half>
          <div
            style={{ width: '100%', display: 'flex', flexDirection: 'column' }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <MainHeader
                  back
                  link="/client/dot"
                  titles={[{ text: 'DOT Reports' }, { text: 'Profile' }]}
                />
              </div>
            </div>
            <Form
              form={form}
              name="dot"
              onFinish={(values) => handleSubmit(values)}
              initialValues={initialValues}
            >
              {DOTFields(
                inspectionsFileds,
                openModalClick,
                errorFieldValue,
              ).map((values, index) => {
                return (
                  <React.Fragment key={index}>
                    <InputsBlock
                      violationsValue={violationsValue}
                      addClick={addClick}
                      add={values.add}
                      title={values.title}
                      blocks={values.blocks}
                    />
                  </React.Fragment>
                );
              })}
            </Form>
            <RecordDotErrorsModal
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
            />
            <div style={{ display: 'flex' }}>
              <Button
                type="primary"
                htmlType="submit"
                className="orange"
                style={{ width: '65px', marginRight: 12 }}
                disabled={!checkPermission(AllPermissionsType.COMPANY_EDIT)}
              >
                Save
              </Button>
              <Button
                className="grey"
                style={{ width: '85px', marginRight: 12 }}
                onClick={() => {
                  form.setFieldsValue(initialValues);
                }}
                disabled={!checkPermission(AllPermissionsType.COMPANY_EDIT)}
              >
                Cancel
              </Button>
            </div>
          </div>
        </MainContainer>
      ) : (
        <NoPermission />
      )}
    </>
  );
};

export default RecordDot;
