import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { Row, Col, DatePicker } from 'antd';

import './log-top-panel.scss';
import dayjs from 'dayjs';
import { getTzValue } from '../../../../utils/utils';

export const LogBottomPanel = (props: any) => {
  const driverLogDate = useSelector(
    (state: any) => state.driverLog?.driverLogDate,
  );
  const [date, setDate] = useState(dayjs(driverLogDate));
  const driverLog = useSelector((state: any) => state.driverLog);
  const tzValue = driverLog?.driverData?.driver?.terminal?.tz_value;
  const tz = getTzValue(tzValue);
  useEffect(() => {
    setDate(dayjs(driverLogDate).tz(tz));
  }, [driverLogDate]);
  const data = [
    {
      data: date.format('MM/DD/YYYY'),
      worked: 0,
      droven: 0,
      vehicle: 440,
      driver: 230,
    },
    {
      data: date.subtract(1, 'day').format('MM/DD/YYYY'),
      worked: 0,
      droven: 0,
      vehicle: 540,
      driver: 30,
    },
    {
      data: date.subtract(2, 'day').format('MM/DD/YYYY'),
      worked: 0,
      droven: 0,
      vehicle: 940,
      driver: 130,
    },
    {
      data: date.subtract(3, 'day').format('MM/DD/YYYY'),
      worked: 0,
      droven: 0,
      vehicle: 20,
      driver: 0,
    },
    {
      data: date.subtract(4, 'day').format('MM/DD/YYYY'),
      worked: 0,
      droven: 0,
      vehicle: 1000,
      driver: 20,
    },
    {
      data: date.subtract(5, 'day').format('MM/DD/YYYY'),
      worked: 0,
      droven: 0,
      vehicle: 450,
      driver: 90,
    },
    {
      data: date.subtract(6, 'day').format('MM/DD/YYYY'),
      worked: 0,
      droven: 0,
      vehicle: 500,
      driver: 10,
    },
    {
      data: date.subtract(7, 'day').format('MM/DD/YYYY'),
      worked: 0,
      droven: 0,
      vehicle: 220,
      driver: 20,
    },
  ];

  return (
    <Row style={{ width: '100%' }}>
      <Col
        span={24}
        className="ubuntu top-log-container"
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <Row style={{ width: '100%', justifyContent: 'space-between' }}>
          <Col style={{ fontWeight: 'bold' }} span={4}>
            <div className="log-bottom-panel-item small-panel-item">Date</div>
            <div className="log-bottom-panel-item-header log-bottom-panel-item small-panel-item">
              Worked | Driving (h)
            </div>
            <div className="log-bottom-panel-item-header log-bottom-panel-item small-panel-item">
              Vehicle | Driver (m)
            </div>
          </Col>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '83%',
            }}
          >
            {data.reverse().map((item, i: number) => {
              return (
                <div key={i} style={{ fontWeight: 'bold' }}>
                  <div className="log-bottom-panel-item log-bottom-panel-item-data small-panel-item">
                    {item.data}
                  </div>
                  <div className="log-bottom-panel-item log-bottom-panel-item-data small-panel-item">
                    0{item.worked + i + 1}:1{i} | 0{item.droven + i + 1}:1{i}
                  </div>
                  <div className="log-bottom-panel-item log-bottom-panel-item-data small-panel-item">
                    {item.vehicle} | {item.driver}
                  </div>
                </div>
              );
            })}
          </div>
        </Row>
      </Col>
    </Row>
  );
};
