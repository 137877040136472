import { Button, DatePicker, Form, Modal, notification } from 'antd';
import { useEffect, useState } from 'react';
import styles from './RecordPage.module.css';
import { InputSelectV2, TextInputV2 } from '../../common/doubleinput';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { getCarriersListReq, getDriverListReq } from '../../../actions';
import { API, ISendDotReport } from '../../../api/API';
import { getValidation, VALIDATION_TYPE } from '../../../utils/validation';

const { REQUIRED } = VALIDATION_TYPE;

interface ICreateRecordModalProps {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const CreateRecordModal = ({
  isModalOpen,
  setIsModalOpen,
}: ICreateRecordModalProps) => {
  const [form] = Form.useForm();

  const user = useSelector((state: any) => state.auth.user);
  const carriers = useSelector((state: any) => state.carrier.carrierList);
  const drivers = useSelector((state: any) => state.driver.driverList);
  const [required, setRequired] = useState(false);
  const [carrierOptions, setCarrierOptions] = useState<any[]>();
  const [driverOptions, setDriverOptions] = useState<any[]>();
  const dispatch = useDispatch();
  const openModal = () => {
    setIsModalOpen((prevState) => !prevState);
    if (isModalOpen) {
      form.setFieldValue([], []);
      const options: any[] = [];
      drivers.map((driver: any) => {
        options.push({
          key: driver.id,
          value: driver.first_name + ' ' + driver.last_name,
        });
      });
      setDriverOptions(options);
    }
  };
  const createRecordInputs = [
    {
      type: 'select',
      title: 'Carrier*',
      placeholder: 'Select Carrier',
      name: 'carrier',
      options: carrierOptions,
    },
    {
      type: 'select',
      title: 'Driver*',
      placeholder: 'Select Driver',
      name: 'driver',
      options: driverOptions,
    },
    {
      type: 'select',
      title: 'Transfer Method*',
      name: 'transferMethod',
      options: [
        {
          key: '1',
          value: 'Web Service',
        },
        {
          key: '2',
          value: 'Email',
        },
      ],
    },
    {
      type: 'range',
      title: 'Date Range*',
      name: 'range',
    },
    {
      type: 'text',
      title: 'Safety official / Investigation Code*',
      name: 'code',
    },
    {
      type: 'text',
      title: 'Notice',
      name: 'note',
    },
  ];
  const { sendDOTReport } = API;

  const onFinish = async () => {
    const { carrier, note, code, range, transferMethod, driver } =
      form.getFieldsValue();

    if (carrier && code && range && transferMethod && driver) {
      const data: ISendDotReport = {
        driver,
        code,
        notice: note,
        method: '1',
        type: transferMethod,
        date_from: dayjs(parseInt(range.split('-')[0])).format('YYYY-MM-DD'),
        date_to: dayjs(parseInt(range.split('-')[1])).format('YYYY-MM-DD'),
        user: user.id,
        origin: '',
      };
      await sendDOTReport(data);
      form.setFieldsValue({
        carrier: '',
        note: '',
        code: '',
        range: '',
        transferMethod: '',
        driver: '',
      });
      return setIsModalOpen(false);
    }
    setRequired(true);
    notification.error({
      type: 'error',
      message: 'Fill in the fileds',
    });
    return;
  };
  function disabledDate(current: any) {
    return current && current > dayjs().endOf('day');
  }
  const { RangePicker } = DatePicker;
  const rangePickerChange = (value: any) => {
    if (value) {
      const [startDate, endDate] = value;
      form.setFieldValue(
        'range',
        `${startDate.valueOf()}-${endDate.valueOf()}`,
      );
    } else {
      form.setFieldValue('range', '');
    }
  };
  const handleSelectChange = () => {
    const value = form.getFieldValue('carrier');
    if (value && drivers) {
      const options: any[] = [];
      const driversFilter = drivers.filter(
        (item: any) => item.carrier.name === value,
      );
      driversFilter.map((driver: any) => {
        options.push({
          key: driver.id,
          value: driver.first_name + ' ' + driver.last_name,
        });
      });
      setDriverOptions(options);
    }
  };
  useEffect(() => {
    if (carriers) {
      const options: any[] = [];
      carriers.map((carrier: any) => {
        options.push({
          key: carrier.name,
          value: carrier.name,
        });
      });
      setCarrierOptions(options);
    }
  }, [carriers]);

  useEffect(() => {
    dispatch(getCarriersListReq({}));
    dispatch(
      getDriverListReq({
        queryParams: {
          with: ['carrier'],
        },
      }),
    );
  }, []);
  useEffect(() => {
    const options: any[] = [];
    drivers.map((driver: any) => {
      options.push({
        key: driver.id,
        value: driver.first_name + ' ' + driver.last_name,
      });
    });
    setDriverOptions(options);
  }, [drivers]);
  return (
    <>
      <div
        onClick={openModal}
        className="orange ubuntu"
        style={{
          fontWeight: 500,
          fontSize: 12,
          marginLeft: 16,
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          borderRadius: 10,
          padding: '10px',
          width: 'fit-content',
          gap: 5,
        }}
      >
        <span
          className="icon-fi-rr-plus ubuntu orange"
          style={{ width: 'fit-content' }}
        />
        Create DOT Report
      </div>
      <Modal
        title="Create DOT Report"
        open={isModalOpen}
        onOk={openModal}
        onCancel={openModal}
        footer={null}
        destroyOnClose
        className={styles.modal}
      >
        <Form form={form} name="test3" onFinish={onFinish}>
          <div className={styles.form}>
            {createRecordInputs.map((input) => (
              <>
                {input.type === 'select' && (
                  <>
                    <InputSelectV2
                      form={form}
                      showSearch
                      title={input.title}
                      name={input.name}
                      options={input.options}
                      placeholder={input.placeholder}
                      onChange={handleSelectChange}
                    />
                  </>
                )}
                {input.type === 'range' && (
                  <Form.Item name={input.name}>
                    <div className="input-item-title">{input.title}</div>
                    <RangePicker
                      name={input.name}
                      onChange={(e) => rangePickerChange(e)}
                      className={styles.rangePicker}
                      disabledDate={disabledDate}
                    />
                  </Form.Item>
                )}
                {input.type === 'text' && (
                  <>
                    <TextInputV2
                      form={form}
                      title={input.title}
                      name={input.name}
                    />
                  </>
                )}
              </>
            ))}
            <div>
              <Form.Item style={{ width: '100%', display: 'flex' }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="orange"
                  style={{ width: '65px', marginRight: 12 }}
                >
                  Save
                </Button>
                <Button
                  className="grey"
                  style={{ width: '85px', marginRight: 12 }}
                  onClick={() => {
                    openModal();
                  }}
                >
                  Cancel
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default CreateRecordModal;
