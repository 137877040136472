import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Row, Col, DatePicker } from 'antd';
import marker from '../../../../img/marker.svg';
import chat from '../../../../img/chat.svg';
import profile from '../../../../img/calendar.svg';
import carrier from '../../../../img/carrier.svg';
import note from '../../../../img/note.svg';
import quarterClock from '../../../../img/quarter-clock.svg';
import userTime from '../../../../img/user-time.svg';
import arrowLeft from '../../../../img/arrow-left.svg';
import arrowRight from '../../../../img/arrow-right.svg';
import dayjs from 'dayjs';
import { getNextDate, getPreviousDate } from '../log-utils';

import './log-top-panel.scss';
import { getLogForm, setDriverLogDate } from '../../../../actions';
import { getTzValue } from '../../../../utils/utils';
import { useChat } from '../../../chat/ChatProvider';

export const LogTopPanel = (props: any) => {
  const { setLoading } = props;
  const driverLogDate = useSelector(
    (state: any) => state.driverLog?.driverLogDate,
  );
  const { openChat, chooseDriver } = useChat();
  const driverLog = useSelector((state: any) => state.driverLog);
  const tzValue = driverLog?.driverData?.driver?.terminal?.tz_value;
  const tz = getTzValue(tzValue);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tz_value = driverLog?.driverData?.driver?.terminal?.tz_value;
  const driverConfigLog = useSelector(
    (state: any) => state?.log?.driverConfigLog?.data,
  );
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    const day = dayjs().tz(tz).format('YYYY-MM-DD');
    setVisible(!day.includes(driverLogDate));
  }, [driverLogDate]);
  useEffect(() => {
    const day = dayjs(driverLogDate).tz(tz).valueOf();
    dispatch(
      getLogForm({
        queryParams: {
          id: driverLog?.driverData?.driver?.id,
          timestamp: day,
        },
      }),
    );
  }, []);

  const chatClick = () => {
    openChat();
    chooseDriver(driverLog?.driverData?.driver);
  };
  return (
    <Row>
      <Col
        span={24}
        className="ubuntu top-log-container"
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <Row>
          <Col
            style={{ display: 'flex', alignItems: 'center' }}
            className="top-log-item-container"
          >
            <img src={marker} alt="marker" />
            <span className="orange ubuntu top-log-item">Call</span>
          </Col>
          <Col
            style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
            className="top-log-item-container"
            onClick={chatClick}
          >
            <img src={chat} alt="chat" />
            <span className="orange ubuntu top-log-item">Chat</span>
          </Col>
          <Col
            style={{ display: 'flex', alignItems: 'center' }}
            className="top-log-item-container"
          >
            <img src={profile} alt="profile" />
            <a
              target="_blank"
              href={`/client/drivers/${driverLog?.driverData?.driver?.id}`}
              className="orange ubuntu top-log-item top-log-codriver "
            >
              Profile
            </a>
          </Col>
          <Col
            style={{ display: 'flex', alignItems: 'center' }}
            className="top-log-item-container"
          >
            <img src={carrier} alt="carrier" />
            <span
              className=" ubuntu top-log-item"
              onClick={() => {
                navigate(
                  `/client/carriers/${driverLog?.driverData?.carrier.id}`,
                );
              }}
            >
              Carrier: {driverLog?.driverData?.carrier?.name}
            </span>
          </Col>
          <Col
            style={{ display: 'flex', alignItems: 'center' }}
            className="top-log-item-container"
          >
            <img src={note} alt="chat" />
            <span className=" ubuntu top-log-item">
              Note:{driverLog?.driverData?.driver?.notes}
            </span>
          </Col>
          <Col
            style={{ display: 'flex', alignItems: 'center' }}
            className="top-log-item-container"
          >
            <img src={quarterClock} alt="clock" />
            <span className=" ubuntu top-log-item">Time Zone: {tz_value}</span>
          </Col>
        </Row>
        <Row>
          <Col
            style={{ display: 'flex', alignItems: 'center', marginRight: 50 }}
            className="top-log-item-container"
          >
            <img src={userTime} alt="clock" />
            <span className=" ubuntu top-log-item">
              CO-Driver:{' '}
              {driverConfigLog?.codriver && (
                <a
                  className="top-log-codriver"
                  target="_blank"
                  href={`/client/drivers/${driverConfigLog?.codriver?.id}/log`}
                >
                  {driverConfigLog?.codriver?.first_name +
                    ' ' +
                    driverConfigLog?.codriver?.last_name}
                </a>
              )}
            </span>
          </Col>
          <Col style={{ display: 'flex', alignItems: 'center' }}>
            <div
              style={{ marginRight: 10, cursor: 'pointer' }}
              onClick={() => {
                dispatch(
                  setDriverLogDate(getPreviousDate(driverLog?.driverLogDate)),
                );
                setLoading(false);
              }}
            >
              <img src={arrowLeft} alt="left" />
            </div>
            <DatePicker
              allowClear={false}
              onChange={(v, dateString) => {
                dispatch(setDriverLogDate(dateString));
              }}
              maxDate={dayjs(driverLogDate).tz(tz)}
              defaultValue={dayjs(driverLogDate).tz(tz)}
              value={dayjs(driverLogDate)}
            />
            <div
              style={{
                marginLeft: 10,
                cursor: visible ? 'pointer' : 'not-allowed',
                opacity: visible ? 1 : 0.3,
                transition: '0.3s',
              }}
              onClick={() => {
                if (visible) {
                  dispatch(
                    setDriverLogDate(getNextDate(driverLog?.driverLogDate)),
                  );
                  setLoading(false);
                }
              }}
            >
              <img src={arrowRight} alt="right" />
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
