import logo from './logo.svg';
import './App.css';
import axios from 'axios';
import {
  generateAllPermissions,
  ALL_PERMISSION_TYPES,
  generatePermission,
} from './constants/role';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { usePermissions } from './hooks/usePermissions';
import io from 'socket.io-client';
import React, { useEffect } from 'react';
import './App.css';
import { useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';
import { debounce } from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import { MainRouter } from './routes';
import { getLoggedInUserReq } from './actions/auth';
import { setScreenSize } from './actions';

import { getScreenDimension } from './utils/screen';

import { screenTypeSelector } from './utils/screen';

function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const loading = useSelector((state: any) => state.auth.loading);
  const { isAuthenticated, user } = useSelector((state: any) => state.auth);
  const state = useSelector((state: any) => state);
  const screen = useSelector((state: any) =>
    screenTypeSelector(state?.screen?.width),
  );

  useEffect(() => {
    dispatch(setScreenSize(getScreenDimension(window)));
  }, []);

  const onScreenResized = debounce((e) => {
    dispatch(setScreenSize(getScreenDimension(e.target)));
  }, 300);

  useEffect(() => {
    window.addEventListener('resize', onScreenResized);
  }, []);

  useEffect(() => {
    dispatch(getLoggedInUserReq());
  }, []);

  if (loading) {
    return <Spin />;
  }

  // if (isAuthenticated) {
  //   if (!user.company?.name) {
  //     navigate("/client/company");
  //   } else {
  //     navigate("/client");
  //   }
  // }

  if (location.pathname === '/' && isAuthenticated) {
    // if (!user.company?.name) {
    //   navigate("/client/company");
    // } else {
    //   navigate("/client");
    // }
    navigate('/client');
  }
  if (location.pathname === '/' && !isAuthenticated) {
    navigate('/signin');
  }
  const theme = createTheme();
  return (
    <div className="app-container ubuntu main hw100">
      {
        <ThemeProvider theme={theme}>
          <MainRouter />
        </ThemeProvider>
      }
    </div>
  );
}

export default App;
