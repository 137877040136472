import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useLocation, useSearchParams } from 'react-router-dom';
import { getCarriersListReq } from '../../../actions/carrier';
import {
  updateMechanicReq,
  getMechanicReq,
  setCurrentMechanicCarrier,
} from '../../../actions/mechanic';

import { Row, Col, Form, Button, Input, Spin } from 'antd';
import { CommonInput } from '../../common/inputs';
import { mechanicForm } from './mechanic-form';
import { InputType } from '../../../constants/inputs';
import { PAGE_STATUS, getDocumentByType } from './constant';
import { CARRIER_SELECT_DISABLED } from '../../common/doubleinput/utils';
import { usePermissions } from '../../../hooks/usePermissions';
import { AllPermissionsType } from '../role/constant';
import { NoPermission } from '../../common/NoPermission';

function buildFormData(formData: any, data: any, parentKey?: any) {
  if (
    data &&
    typeof data === 'object' &&
    !(data instanceof Date) &&
    !(data instanceof File)
  ) {
    Object.keys(data).forEach((key) => {
      buildFormData(
        formData,
        data[key],
        parentKey ? `${parentKey}[${key}]` : key,
      );
    });
  } else {
    const value = data == null ? '' : data;

    formData.append(parentKey, value);
  }
}

function jsonToFormData(data: any) {
  const formData = new FormData();

  buildFormData(formData, data);

  return formData;
}

export const MechanicPage = () => {
  const [form] = Form.useForm();
  const params = useParams();
  const location = useLocation();
  const [search, setSearch] = useSearchParams();
  const [state, setStateValue] = React.useState(search.get('state'));
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = useState({
    name: '',
    usdot: '',
    phone: '',
    carrier: '',
    email: '',
    status: null,
    notes: '',
    terminal: null,
    password: '',
    login: '',
  });
  const { loading, mechanic, currentCarrier } = useSelector(
    (state: any) => state.mechanic,
  );
  const { loading: carrierLoading, carrierList } = useSelector(
    (state: any) => state.carrier,
  );

  const { user } = useSelector((state: any) => state.auth);
  const [fields, setFields] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (mechanic?.carrier?.id) {
      const foundCarrier = carrierList.find(
        (carrier: any) => carrier.id === mechanic?.carrier.id,
      );

      dispatch(
        setCurrentMechanicCarrier({
          ...foundCarrier,
          defaultSavedCarrier: true,
        }),
      );
    }
  }, [carrierList, mechanic]);

  React.useEffect(() => {
    setStateValue(search.get('state'));
  }, [search]);

  React.useEffect(() => {
    dispatch(
      getCarriersListReq({
        queryParams: {
          with: ['settings', 'terminals', 'driver_groups', 'documents'],
        },
      }),
    );
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      ...form.getFieldsValue(),
      ...(!currentCarrier.defaultSavedCarrier ? currentCarrier?.settings : {}),
    });
  }, [currentCarrier]);

  useEffect(() => {
    dispatch(
      getMechanicReq({
        mechanicId: params.mechanicId,
        queryParams: {
          with: ['terminal', 'group', 'carrier', 'documents'],
        },
      }),
    );
  }, []);

  const handleSubmit = async (values: any) => {
    const f = Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
    const data = jsonToFormData({
      ...values,
    });

    dispatch(
      updateMechanicReq({
        values: data,
        mechanicId: params.mechanicid,
      }),
    );
  };

  React.useEffect(() => {
    setInitialValues({
      ...initialValues,
      ...mechanic,
      carrier: mechanic?.carrier?.id,
      terminal: mechanic?.terminal?.id,
    });
    form.setFieldsValue({
      ...initialValues,
      ...mechanic,
      carrier: mechanic?.carrier?.id,
      terminal: mechanic?.terminal?.id,
    });
  }, [mechanic]);

  const { checkPermission } = usePermissions();

  return (
    <>
      {checkPermission(AllPermissionsType.MECHANIC_SHOW) ? (
        <Row style={{ paddingLeft: 23, paddingRight: 25, height: '100%' }}>
          {/* <Graph /> */}

          {loading ? (
            <div
              style={{
                width: '100%',
                height: '100%',
                minHeight: 600,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Spin />
            </div>
          ) : (
            <Col span={24}>
              <Form
                form={form}
                name="test"
                onFinish={handleSubmit}
                initialValues={initialValues}
              >
                {mechanicForm({}).map((fieldCurrent: any, i: number) => {
                  const field = {
                    ...fieldCurrent,
                    disabled:
                      state === PAGE_STATUS.VIEW ||
                      !checkPermission(AllPermissionsType.MECHANIC_EDIT),
                    isReadonlyCarrier: true,
                  };
                  if (CARRIER_SELECT_DISABLED.includes(field.type)) {
                    return (
                      <CommonInput
                    currentIndex={currentIndex}
                    fields={fields}

                    key={i}
                    setCurrentIndex={setCurrentIndex}
                    {...field}
                    form={form}
                    isReadonlyCarrier={true}
                  />
                      // prettier-ignore
                    );
                  }

                  if (field.type === InputType.ADD_DYNAMIC) {
                    return (
                      <CommonInput
                    currentIndex={currentIndex}
                    fields={fields}

                    key={i}
                    setCurrentIndex={setCurrentIndex}
                    {...field}
                    form={form}
                  />
                      // prettier-ignore
                    );
                  }
                  return (
                    <CommonInput
                      key={i}
                      {...field}
                      form={form}
                      showDocsList={true}
                    />
                  );
                })}
                <Form.Item style={{ width: '100%', display: 'flex' }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="orange"
                    style={{ width: '65px', marginRight: 12 }}
                    disabled={
                      state === PAGE_STATUS.VIEW ||
                      !checkPermission(AllPermissionsType.MECHANIC_EDIT)
                    }
                  >
                    Save
                  </Button>
                  <Button
                    className="grey"
                    style={{ width: '85px', marginRight: 12 }}
                    onClick={() => {
                      form.setFieldsValue(initialValues);
                    }}
                    disabled={
                      state === PAGE_STATUS.VIEW ||
                      !checkPermission(AllPermissionsType.MECHANIC_EDIT)
                    }
                  >
                    Cancel
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          )}
        </Row>
      ) : (
        <NoPermission />
      )}
    </>
  );
};
