import { useState, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Form, Col } from 'antd';

import { ConfigProvider, DatePicker, Space, Typography } from 'antd';
import en from 'antd/es/date-picker/locale/en_US';
import enUS from 'antd/es/locale/en_US';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';

export const TimePickerLogFieldTest = (props: any) => {
  const {
    rules = [],
    name = '',
    icon,
    placeholder = '',
    label = '',
    disabled,
    width = '100%',
    title = '',
    isSecondField = false,
    span = 24,
    styles = {},
    pathName = '',
    form,
    onChange,
  } = props;
  dayjs.extend(buddhistEra);
  const buddhistLocale: typeof en = {
    ...en,
    lang: {
      ...en.lang,
      fieldDateTimeFormat: 'MM-DD-YYYY',
      yearFormat: 'YYYY',
      cellYearFormat: 'YYYY',
    } as any,
  };
  const [value, setValue] = useState<number>();
  // ConfigProvider level locale
  const globalBuddhistLocale: typeof enUS = {
    ...enUS,
    DatePicker: {
      ...enUS.DatePicker!,
      lang: buddhistLocale.lang,
    },
  };

  const driverLogDate = useSelector(
    (state: any) => state?.driverLog?.driverLogDate,
  );

  const getName = useMemo(() => {
    return pathName ? [...pathName, name] : name;
  }, [pathName, name]);

  const defaultV = !!form?.getFieldValue(getName)
    ? dayjs(form.getFieldValue(getName))
    : dayjs(driverLogDate);
  const change = (e: any) => {
    setValue(e);
    form.setFieldValue(getName, e);
  };
  useEffect(() => {
    setTimeout(() => {
      const val = form.getFieldValue(getName);
      setValue(val);
    }, 100);
  }, [getName]);
  console.log(value, 'value');

  return (
    <Col
      span={span}
      className="input-container-v2"
      style={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      {title ? (
        title === 'invisible' ? (
          <div
            className="input-item-title input-title ubuntu"
            style={{ opacity: 0 }}
          >
            {title}
          </div>
        ) : (
          <div className="input-item-title input-title ubuntu">{title}</div>
        )
      ) : null}
      <DatePicker
        style={{ width }}
        value={value != 0 ? dayjs(value) : dayjs()}
        format={'MM/DD/YYYY'}
        onChange={(e, timeString) => {
          change(timeString);
        }}
      />
      <Form.Item
        style={{ position: 'absolute', zIndex: -1 }}
        name={getName}
      ></Form.Item>
    </Col>
  );
};
