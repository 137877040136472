import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useLocation, useSearchParams } from 'react-router-dom';
import {
  getCarrierReq,
  updateCarrierReq,
  getCarrierPasswordReq,
  getCarrierContactsSucces,
} from '../../../actions/carrier';

import { Row, Col, Form, Button, Input, Spin } from 'antd';
import { CommonInput } from '../../common/inputs';
import { carrierForm } from './carrier-form';
import { SetPassword } from './modals/CarrierSetPassword';
import { InputType } from '../../../constants/inputs';
import { CarrierField, PAGE_STATUS } from './constant';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { usePermissions } from '../../../hooks/usePermissions';
import { AllPermissionsType } from '../role/constant';
import { NoPermission } from '../../common/NoPermission';
import { IContacts } from '../../../types';
import MainContainer from '../../layout/MainContainer';
import { cleanObject } from '../../../utils/cleanObject';

dayjs.extend(customParseFormat);

function buildFormData(formData: any, data: any, parentKey?: any) {
  if (
    data &&
    typeof data === 'object' &&
    !(data instanceof Date) &&
    !(data instanceof File)
  ) {
    Object.keys(data).forEach((key) => {
      buildFormData(
        formData,
        data[key],
        parentKey ? `${parentKey}[${key}]` : key,
      );
    });
  } else {
    const value = data == null ? '' : data;

    formData.append(parentKey, value);
  }
}

function jsonToFormData(data: any) {
  const formData = new FormData();

  buildFormData(formData, data);

  return formData;
}

export const CarrierPage = () => {
  const [form] = Form.useForm();
  const params = useParams();
  const location = useLocation();
  const [search, setSearch] = useSearchParams();
  const [state, setStateValue] = React.useState(search.get('state'));
  const dispatch = useDispatch();
  const { loading, carrier } = useSelector((state: any) => state.carrier);
  const contacts: IContacts = useSelector(
    (state: any) => state?.carrier?.contacts,
  );
  const { user } = useSelector((state: any) => state.auth);
  const [fields, setFields] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const { checkPermission } = usePermissions();
  const [accautnModalOpen, setAccauntModalOpen] = useState(false);
  const [currentCarrier, setCurrentCarrier] = useState({
    id: '',
    name: '',
  });

  React.useEffect(() => {
    setStateValue(search.get('state'));
  }, [search]);

  const [initialValues, setInitialValues] = useState({
    name: '',
    usdot: '',
    phone: '',
    mcnumber: '',
    email: '',
    person: '',
    status: null,
    notes: '',
    email_second: '',
    settings: {
      measurement_system: null,
      dst: null,
      first_day: null,
      compliance_mode: null,
      motion_treshold: null,
      cargo_type: [],
      restart: null,
      rest_break: null,
      short_haul: false,
      personal_conveyance: false,
      adverse_conditions: false,
      unlimited_documents: false,
      unlimited_trailers: false,
      yard_move: false,
      exempt_driver: false,
      exempt_driver_notice: false,
      period_starting_time: '',
      motion_threshold: '',
    },
    terminals: [
      {
        name: '',
        country: 1,
        state: 1,
        area: '',
        address_index: '',
        tz: 1,
        number_street: '',
        authority: false,
      },
    ],
  });
  useEffect(() => {
    dispatch(
      getCarrierReq({
        carrierId: params.carrierid,
        queryParams: {
          with: ['settings', 'terminals', 'offices', 'company'],
        },
      }),
    );
  }, []);

  const setAllContacts = (contacts: string[], values: string[]) => {
    values.map((value, index) => {
      const contact = contacts[index];
      form.setFieldValue(contact, value);
    });
  };
  const setContactsFilds = () => {
    const contact = [
      CarrierField.EMAIL,
      CarrierField.EMAIL_SECOND,
      CarrierField.PERSON,
      CarrierField.PHONE,
      CarrierField.PHONE_ACCOUNTING,
      CarrierField.PHONE_ELD_SUPPRORT,
      CarrierField.PHONE_FLEET_MANAGER,
      CarrierField.PHONE_OVERSIZE_PERMITS,
      CarrierField.PHONE_SAFETY_SUPPORT,
    ];
    const values = [
      contacts?.email,
      contacts?.email_second,
      contacts?.person,
      contacts?.phone,
      contacts?.phone_accounting,
      contacts?.phone_eld,
      contacts?.phone_fleet,
      contacts?.phone_permits,
      contacts?.phone_safety,
    ];
    setAllContacts(contact, values);
    return true;
  };
  function findChangedIndex(arr1: any, arr2: any) {
    if (arr1.length !== arr2.length) {
      return arr1.length - 1;
    }

    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i].authority !== arr2[i].authority) {
        return i;
      }
    }

    return -1;
  }

  const test = (values: any, updatedTerminalsIndex: number) => {
    const terminals = values.terminals.map((tr: any, index: number) => {
      if (index === updatedTerminalsIndex) {
        return tr;
      } else {
        tr.authority = false;
        return tr;
      }
    });
    return terminals;
  };
  const handleSubmit = async (values: any) => {
    let updatedTerminalsIndex = findChangedIndex(
      values.terminals,
      carrier.terminals,
    );
    const terminals = test(values, updatedTerminalsIndex);

    const data = {
      ...values,
      settings: {
        ...values.settings,
        cargo_type: values.settings?.cargo_type?.map((type: any) => `${type}`),
      },
      terminals,
      company: user.company.id,
      offices: [...user.offices]?.map((office) => office.id),
    };

    dispatch(
      updateCarrierReq({
        values: data,
        carrierId: params.carrierid,
      }),
    );
  };

  React.useEffect(() => {
    form.setFieldsValue({
      ...carrier,
      settings: {
        ...carrier.settings,
        cargo_type: carrier.settings?.cargo_type?.map((type: any) => +type),
      },
    });
    setInitialValues({
      ...carrier,
      settings: {
        ...carrier.settings,
        cargo_type: carrier.settings?.cargo_type?.map((type: any) => +type),
      },
    });
  }, [carrier]);

  const [visible, setVisible] = useState(false);

  const getContactsClick = async () => {
    dispatch(
      getCarrierContactsSucces({
        carrierId: params.carrierid,
      }),
    );
  };
  useEffect(() => {
    if (contacts) {
      const response = setContactsFilds();
      setVisible(response);
    }
  }, [contacts]);
  const onChange = () => {
    let value: string = form.getFieldValue([
      'settings',
      'period_starting_time',
    ]);
    if (value) {
      value = value.replace(/\D/g, '');

      const hours = parseInt(value.slice(0, 2), 10);
      if (hours < 0 || hours > 24) {
        value = value.slice(0, 2);
      }
      const minutes = parseInt(value.slice(2, 4), 10);
      if (minutes < 0 || minutes > 59) {
        value = value.slice(0, 4);
      }
      const seconds = parseInt(value.slice(4, 6), 10);
      if (seconds < 0 || seconds > 59) {
        value = value.slice(0, 5);
      }

      value = value.slice(0, 6);
      value = value.replace(/(..)(?!$)/g, '$1:');
      form.setFieldValue(['settings', 'period_starting_time'], value);
    }
  };
  const deleteLogo = () => {
    form.setFieldValue('logo', '');
  };
  return (
    <>
      {checkPermission(AllPermissionsType.CARRIER_SHOW) ? (
        <MainContainer half>
          <Row
            style={{
              height: '100%',
              width: '100%',
              position: 'relative',
            }}
          >
            {loading ? (
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  minHeight: 600,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Spin />
              </div>
            ) : (
              <Col span={24}>
                <Form
                  form={form}
                  onFinish={handleSubmit}
                  initialValues={initialValues}
                  onChange={onChange}
                >
                  {carrierForm({
                    visible: visible,
                    onClick: getContactsClick,
                    deleteLogo: deleteLogo,
                  }).map((fieldCurrent: any, i: number) => {
                    const field = {
                      ...fieldCurrent,
                      disabled:
                        state === PAGE_STATUS.VIEW ||
                        !checkPermission(AllPermissionsType.CARRIER_EDIT),
                    };
                    if (field.type === InputType.ADD_DYNAMIC) {
                      return (
                        <CommonInput
                          currentIndex={currentIndex}
                          fields={fields}
                          key={i}
                          setCurrentIndex={setCurrentIndex}
                          {...field}
                          form={form}
                        />
                      );
                    }
                    return <CommonInput key={i} {...field} form={form} />;
                  })}
                  <Form.Item style={{ width: '100%', display: 'flex' }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="orange"
                      style={{ width: '65px', marginRight: 12 }}
                      disabled={
                        state === PAGE_STATUS.VIEW ||
                        !checkPermission(AllPermissionsType.CARRIER_EDIT)
                      }
                    >
                      Save
                    </Button>
                    <Button
                      className="grey"
                      style={{ width: '85px', marginRight: 12 }}
                      onClick={() => {
                        form.setFieldsValue(initialValues);
                      }}
                      disabled={
                        state === PAGE_STATUS.VIEW ||
                        !checkPermission(AllPermissionsType.CARRIER_EDIT)
                      }
                    >
                      Cancel
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            )}
          </Row>
        </MainContainer>
      ) : (
        <NoPermission />
      )}
    </>
  );
};
