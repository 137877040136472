import { useNavigate } from 'react-router-dom';
import styles from './MainHeader.module.css';
import { motion } from 'framer-motion';
import BackAngleIcon from '../../img/exitIcon.svg';
import { SetPassword } from '../modules/carrier/modals/CarrierSetPassword';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { getCarrierPasswordReq } from '../../actions';
interface IMainHeaderProps {
  titles: { text: string }[];
  back?: boolean;
  link?: string;
  password?: boolean;
}

type MainHeaderPropsWithCondition = IMainHeaderProps &
  ({ back: true; link: string } | { back?: false; link?: string });

const MainHeader = ({
  titles,
  back,
  link,
  password,
}: MainHeaderPropsWithCondition) => {
  const navigate = useNavigate();
  const backClick = () => {
    if (!back) return;
    navigate(link);
  };
  const [currentCarrier, setCurrentCarrier] = useState({
    id: '',
    name: '',
  });
  const [accautnModalOpen, setAccauntModalOpen] = useState(false);
  const dispatch = useDispatch();

  return (
    <div className={styles.header}>
      <div className={styles.headerCon}>
        {back && (
          <motion.button
            initial={{
              opacity: 0,
              scale: 0,
            }}
            whileInView={{
              opacity: [0, 1],
              scale: [0, 1],
            }}
            onClick={backClick}
            className={styles.back}
          >
            <img src={BackAngleIcon} alt="back" />
          </motion.button>
        )}
        {titles.map((title, index) => (
          <h2
            key={index}
            className={`${index != 0 ? `${styles.second}` : `${styles.main}`}`}
          >
            {index != 0 && <span className={styles.point} />}
            {title.text}
          </h2>
        ))}
      </div>

      {password && (
        <>
          <div
            style={{
              color: '#374957',
              display: 'flex',
              alignItems: 'center',
              zIndex: 2,
              cursor: 'pointer',
            }}
            onClick={() => setAccauntModalOpen((prevState) => !prevState)}
          >
            <span
              className="icon-fi-rr-lock"
              style={{ marginRight: '10px' }}
            ></span>{' '}
            Set Password
          </div>
          <SetPassword
            currentItem={currentCarrier}
            isOpen={accautnModalOpen}
            toggleModal={(status: any) => setAccauntModalOpen(status)}
            onSubmit={(payload: any) => {
              dispatch(
                getCarrierPasswordReq({
                  data: payload,
                  onSuccess: () => {
                    setAccauntModalOpen(false);
                  },
                }),
              );
            }}
          />
        </>
      )}
    </div>
  );
};

export default MainHeader;
