import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { Table, Row, Col, Select, Button } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useTableParams } from '../../../hooks/useTableParams';
import {
  getCarriersListReq,
  getCarrierPasswordReq,
} from '../../../actions/carrier';
import { getUnitListReq } from '../../../actions/unit';
import { getParams } from '../../../routes/utils';
import { InputSearch } from '../../common/doubleinput/InputSearch';
import { getOrderFromTableParams } from '../../../hooks/utils';
import { SetPassword } from './modals/CarrierSetPassword';

import ResetSort from '../../../img/resetSort.svg';
import ResetFilter from '../../../img/resetFilter.svg';
import { usePermissions } from '../../../hooks/usePermissions';
import { AllPermissionsType } from '../role/constant';
import { NoPermission } from '../../common/NoPermission';
import { OpenLayerMap } from '../../common/google-map/olMap';
import MainContainer from '../../layout/MainContainer';
import MainHeader from '../../header/MainHeader';

export const UnitList: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    handleTableChange,
    onSuccess,
    tableParams,
    rowSelection,
    clearOrderFilters,
    setSearchParam,
    hasFiltersOrOrder,
    clearFilter,
    clearOrder,
    clearCustomFilter,
    setCustomFilter,
  } = useTableParams({});

  const [units, setUnits] = useState<any>([]);
  const [info, setInfo] = useState<any>([]);
  const [countV, setCount] = useState<any>(0);
  const [room, setRoom] = useState('');
  const [message, setMessage] = useState('');
  const [messageReceived, setMessageReceived] = useState('');

  const unitsRedux = useSelector((state: any) => state.units.units);
  const count = useSelector((state: any) => state.driver.count);
  const loading = useSelector((state: any) => state.driver.loading);
  const [accautnModalOpen, setAccauntModalOpen] = useState(false);
  const [currentCarrier, setCurrentCarrier] = useState({
    id: '',
    name: '',
  });

  React.useEffect(() => {
    setUnits(unitsRedux);
  }, [unitsRedux]);

  // const joinRoom = () => {
  //   if (room !== '') {
  //     socket.emit('join_room', room);
  //   }
  // };

  // const sendMessage = () => {
  //   socket.emit('send_message', { message, room });
  // };

  // useEffect(() => {
  //   socket.on('receive_message', (data: any) => {
  //     console.log('receive message', data);
  //     setMessageReceived(data.message);
  //   });
  //   socket.on('message', (data: any) => {
  //     console.log('message', data.message);
  //     if (data?.message) {
  //       setInfo(JSON.parse(data.message));
  //       setCount((state: any) => {
  //         return state + 1;
  //       });
  //     }
  //   });
  // }, [socket]);

  const options = [
    {
      value: 0,
      text: 'Off',
    },
    {
      value: 1,
      text: 'On',
    },
    {
      value: 2,
      text: 'SB',
    },
    {
      value: 3,
      text: 'D',
    },
    {
      value: 4,
      text: 'YM',
    },
    {
      value: 5,
      text: 'PC',
    },
  ];
  const columns: ColumnsType<any> = [
    Table.SELECTION_COLUMN,
    {
      title: 'Driver',
      key: 'driver',
      dataIndex: 'driver',
      sortOrder: getOrderFromTableParams('driver', tableParams),
      sorter: {
        compare: (a: any, b: any) => a.driver - b.driver,
        multiple: 5,
      },
      render: (name, record, index) => {
        return (
          <div
            className="ubuntu pointer"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <div
              className="driver-marker"
              style={{
                marginRight: 10,
                backgroundColor: record.color,
              }}
            />
            <div>
              {record?.driver?.first_name} {record?.driver?.last_name}
            </div>
          </div>
        );
      },

      width: '18%',
      ellipsis: true,
      filterDropdown: () => {
        return (
          <div style={{ padding: 10 }}>
            <div>
              <Select
                style={{ width: 200, marginBottom: 20 }}
                value={tableParams.filters?.driver}
                onChange={(value) => {
                  clearCustomFilter('group');
                  setCustomFilter('driver', value);
                }}
              >
                {unitsRedux.map((unit: any) => (
                  <Select.Option key={unit.driver?.id} value={unit.driver?.id}>
                    {unit.driver?.first_name} {unit.driver?.last_name}
                  </Select.Option>
                ))}
              </Select>
            </div>
            <Button
              style={{ width: 80, height: 40 }}
              className="orange"
              onClick={() => {
                clearCustomFilter('driver');
                clearCustomFilter('group');
              }}
            >
              Reset
            </Button>
          </div>
        );
      },

      filteredValue: tableParams?.filters?.driver || null,
    },
    {
      title: 'Vehicle',
      dataIndex: 'vehicale',
      key: 'vehicale',
      sortOrder: getOrderFromTableParams('vehicale', tableParams),
      sorter: {
        compare: (a: any, b: any) => a.vehicale - b.vehicale,
        multiple: 5,
      },
      render: (name, record, index) => {
        return (
          <div
            className="ubuntu"
            style={{ color: '#141029', cursor: 'pointer' }}
          >
            {record?.vehicle?.identificator}
          </div>
        );
      },
      ellipsis: true,
      width: '15%',
      filterDropdown: () => {
        return (
          <div style={{ padding: 10 }}>
            <div>
              <Select
                style={{ width: 200, marginBottom: 20 }}
                value={tableParams.filters?.vehicle}
                onChange={(value) => {
                  clearCustomFilter('group');
                  setCustomFilter('vehicle', value);
                }}
              >
                {unitsRedux.map((unit: any) => (
                  <Select.Option
                    key={unit.vehicle?.id}
                    value={unit.vehicle?.id}
                  >
                    {unit.vehicle?.identificator}
                  </Select.Option>
                ))}
              </Select>
            </div>
            <Button
              style={{ width: 80, height: 40 }}
              className="orange"
              onClick={() => {
                clearCustomFilter('vehicle');
                clearCustomFilter('group');
              }}
            >
              Reset
            </Button>
          </div>
        );
      },

      filteredValue: tableParams?.filters?.vehicle || null,
    },
    {
      title: 'ELD',
      dataIndex: 'eld',
      key: 'eld',
      sortOrder: getOrderFromTableParams('eld', tableParams),
      sorter: {
        compare: (a: any, b: any) => a.eld - b.eld,
        multiple: 5,
      },
      render: (name, record, index) => {
        return (
          <div
            className="ubuntu"
            style={{ color: '#141029', cursor: 'pointer' }}
          >
            {record?.device?.identificator}
          </div>
        );
      },
      ellipsis: true,
      width: '13%',
      filterDropdown: () => {
        return (
          <div style={{ padding: 10 }}>
            <div>
              <Select
                style={{ width: 200, marginBottom: 20 }}
                value={tableParams.filters?.device}
                onChange={(value) => {
                  clearCustomFilter('group');
                  setCustomFilter('device', value);
                }}
              >
                {unitsRedux.map((unit: any) => (
                  <Select.Option key={unit.device?.id} value={unit.device?.id}>
                    {unit.device?.identificator}
                  </Select.Option>
                ))}
              </Select>
            </div>
            <Button
              style={{ width: 80, height: 40 }}
              className="orange"
              onClick={() => {
                clearCustomFilter('device');
                clearCustomFilter('group');
              }}
            >
              Reset
            </Button>
          </div>
        );
      },
      filteredValue: tableParams?.filters?.device || null,
    },
    {
      title: 'Co Driver',
      dataIndex: 'codriver',
      key: 'codriver',
      sortOrder: getOrderFromTableParams('codriver', tableParams),
      sorter: {
        compare: (a: any, b: any) => a.codriver - b.codriver,
        multiple: 5,
      },
      render: (name, record, index) => {
        return (
          <div
            className="ubuntu"
            style={{ color: '#141029', cursor: 'pointer' }}
          >
            {record?.codriver?.first_name} {record?.codriver?.last_name}
          </div>
        );
      },
      ellipsis: true,
      width: '12%',
      filterDropdown: () => {
        const seen = new Set<number>();
        const filtredCodriver = unitsRedux.filter((item: any) => {
          if (item.codriver?.id === undefined) {
            return false;
          } else if (seen.has(item.codriver?.id)) {
            return false;
          } else {
            seen.add(item.codriver?.id);
            return true;
          }
        });
        return (
          <div style={{ padding: 10 }}>
            <div>
              <Select
                style={{ width: 200, marginBottom: 20 }}
                value={tableParams.filters?.codriver}
                onChange={(value) => {
                  clearCustomFilter('group');
                  setCustomFilter('codriver', value);
                }}
              >
                {filtredCodriver.map((unit: any) => (
                  <Select.Option
                    key={unit.codriver?.id}
                    value={unit.codriver?.id}
                  >
                    {unit.codriver?.first_name} {unit.codriver?.last_name}
                  </Select.Option>
                ))}
              </Select>
            </div>
            <Button
              style={{ width: 80, height: 40 }}
              className="orange"
              onClick={() => {
                clearCustomFilter('codriver');
                clearCustomFilter('group');
              }}
            >
              Reset
            </Button>
          </div>
        );
      },
      filteredValue: tableParams?.filters?.codriver || null,
    },
    {
      title: 'Carrier',
      dataIndex: 'carrier',
      key: 'carrier',
      render: (name, record, index) => {
        return (
          <div
            className="ubuntu"
            style={{ color: '#141029', cursor: 'pointer' }}
          >
            {record?.carrier?.name}
          </div>
        );
      },
      ellipsis: true,
      width: '10%',
      filterDropdown: () => {
        const seen = new Set<number>();
        const filtredCarriers = unitsRedux.filter((item: any) => {
          if (seen.has(item.carrier?.id)) {
            return false;
          } else {
            seen.add(item.carrier?.id);
            return true;
          }
        });
        return (
          <div style={{ padding: 10 }}>
            <div>
              <Select
                style={{ width: 200, marginBottom: 20 }}
                value={tableParams.filters?.carrier}
                onChange={(value) => {
                  // clearCustomFilter('group');
                  // setCustomFilter('carrier', value);
                }}
              >
                {filtredCarriers.map((unit: any) => (
                  <Select.Option
                    key={unit.carrier?.id}
                    value={unit.carrier?.id}
                  >
                    {unit.carrier?.name}
                  </Select.Option>
                ))}
              </Select>
            </div>
            <Button
              style={{ width: 80, height: 40 }}
              className="orange"
              onClick={() => {
                // clearCustomFilter('carrier');
                // clearCustomFilter('group');
              }}
            >
              Reset
            </Button>
          </div>
        );
      },
      filteredValue: tableParams?.filters?.carrier || null,
    },
    // {
    //   title: 'Worked',
    //   dataIndex: 'worked',
    //   key: 'worked',
    //   width: '10%',
    //   sortOrder: getOrderFromTableParams('worked', tableParams),
    //   sorter: {
    //     compare: (a: any, b: any) => a.worked - b.worked,
    //     multiple: 5,
    //   },
    //   render: (name, record, index) => {
    //     return (
    //       <div
    //         className="ubuntu orange"
    //         style={{ color: '#141029', cursor: 'pointer' }}
    //       >
    //         {record?.break}
    //         {countV + index}
    //       </div>
    //     );
    //   },
    //   ellipsis: true,
    // },
    {
      title: 'Location',
      dataIndex: 'worked',
      key: 'worked',
      width: '10%',
      render: (name, record, index) => {
        return (
          <div
            className="ubuntu orange"
            style={{ color: '#141029', cursor: 'pointer' }}
          >
            location
          </div>
        );
      },
      ellipsis: true,
    },
    {
      title: 'Violations',
      dataIndex: 'violations',
      key: 'violations',
      width: '9%',
      render: (name, record, index) => {
        return (
          <div
            className="ubuntu orange"
            style={{
              color: '#141029',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {record?.violations}
          </div>
        );
      },
      filters: [
        { value: 'No', key: 'No' },
        { value: 'Form & Manner', key: 2 },
        { value: 'HOS', key: 3 },
      ].map((st: any) => {
        return {
          text: st.value,
          value: st.key,
        };
      }),
      filteredValue: tableParams?.filters?.violations || null,
      ellipsis: true,
    },
    {
      title: 'Duty Status',
      dataIndex: 'status',
      key: 'status',
      width: '10%',
      ellipsis: true,
      render: (value, record, index) => {
        return <div>{record?.status_value}</div>;
      },
      filters: options.map((value) => {
        return {
          text: value.text,
          value: value.value,
        };
      }),
      filteredValue: tableParams?.filters?.status || null,
    },
  ];

  useEffect(() => {
    dispatch(
      getUnitListReq({
        queryParams: {
          ...getParams(tableParams),
          with: ['terminal', 'carrier', 'group', 'vehicle', 'codriver'],
        },
      }),
    );
  }, [tableParams]);

  const { checkPermission } = usePermissions();
  const onRowClick = (record: any) => {
    navigate(`${location.pathname}/${record.id}`);
  };
  return (
    <>
      {checkPermission(AllPermissionsType.DRIVER_LIST) ? (
        <>
          <MainContainer>
            <Row>
              <SetPassword
                currentItem={currentCarrier}
                isOpen={accautnModalOpen}
                toggleModal={(status: any) => setAccauntModalOpen(status)}
                onSubmit={(payload: any) => {
                  dispatch(
                    getCarrierPasswordReq({
                      data: payload,
                      onSuccess: () => {
                        dispatch(
                          getCarriersListReq({
                            queryParams: getParams(tableParams),
                          }),
                        );
                        setAccauntModalOpen(false);
                      },
                    }),
                  );
                }}
              />

              <Col span={12} style={{ marginBottom: 30 }}>
                <MainHeader titles={[{ text: 'Units' }]} />
              </Col>
              <Col span={24} style={{ marginBottom: 25 }}>
                <OpenLayerMap />
              </Col>
              <Col
                span={24}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  marginBottom: 25,
                }}
              >
                <InputSearch
                  onChange={setSearchParam}
                  onClear={clearOrderFilters}
                  hasFilters={hasFiltersOrOrder}
                />
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span className="icon-fi-rr-plus ubuntu orange" />
                  <div
                    className="orange ubuntu"
                    style={{
                      fontWeight: 500,
                      fontSize: 12,
                      marginLeft: 8,
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      navigate(`${location.pathname}/create`);
                    }}
                  >
                    Create Unit
                  </div>
                </div>
                <div style={{ marginLeft: 25, display: 'flex' }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                    onClick={clearOrder}
                  >
                    <div style={{ marginRight: 5 }}>
                      <img src={ResetSort} />
                    </div>
                    <div
                      className="ubuntu"
                      style={{ color: '#8A8996', fontSize: 12 }}
                    >
                      Reset sorting
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                      marginLeft: 25,
                    }}
                    onClick={clearFilter}
                  >
                    <div style={{ marginRight: 5 }}>
                      <img src={ResetFilter} />
                    </div>
                    <div
                      className="ubuntu"
                      style={{ color: '#8A8996', fontSize: 12 }}
                    >
                      Reset filter
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <div className="logs-table" style={{ width: '100%' }}>
              <Table
                columns={columns}
                rowKey={(record) => record.id}
                dataSource={units.map((carrier: any, index: any) => {
                  return {
                    ...carrier,
                  };
                })}
                onRow={(record) => ({
                  onClick: () => onRowClick(record),
                })}
                rowClassName="pointer-row"
                pagination={{
                  ...tableParams.pagination,
                  position: ['bottomCenter'],
                  total: count,
                }}
                loading={loading}
                onChange={handleTableChange}
                rowSelection={{ ...rowSelection, columnWidth: 10 }}
                className="table-custom"
              />
            </div>
          </MainContainer>
        </>
      ) : (
        <NoPermission />
      )}
    </>
  );
};
