import { useEffect, useLayoutEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { getLogForm } from '../../../actions';
import { DriverLogs } from './LogTabs/Chart/interface/ChartInterface';

export const LogTab = () => {
  const driverLog = useSelector(
    (state: any) => state.driverLog?.driverData?.driver,
  );
  const driverLogDate = useSelector(
    (state: any) => state.driverLog?.driverLogDate,
  );
  const driverConfigLog = useSelector(
    (state: any) => state?.log?.driverConfigLog?.data,
  );
  const logs: DriverLogs = useSelector((state: any) => state.driverLog.logList);
  const dispatch = useDispatch();
  const [data, setData] = useState<any>([]);

  const columns: ColumnsType<any> = [
    {
      title: 'Start Time',
      key: 'date',
      dataIndex: 'identificator',
      render: (name, record, index) => {
        const formattedDate = dayjs(record.timestamp).format(
          'MM/DD/YYYY hh:mm:ss A',
        );
        return <div style={{ marginLeft: 10 }}>{formattedDate}</div>;
      },
      width: '24%',
      ellipsis: true,
    },
    {
      title: 'Vehicle',
      key: 'vehicle',
      dataIndex: 'vehicale',
      render: (name, record, index) => {
        return <>{record.vehicle}</>;
      },
      width: '24%',
      ellipsis: true,
    },
    {
      title: 'VIN',
      key: 'vin',
      dataIndex: 'vin',
      render: (name, record, index) => {
        return <>{record.vin}</>;
      },
      width: '29%',
      ellipsis: true,
    },
    {
      title: 'ELD',
      key: 'eld',
      dataIndex: 'eld',
      render: (name, record, index) => {
        return <>{record.eld}</>;
      },
      width: '24%',
      ellipsis: true,
    },
    {
      title: 'Trailers',
      key: 'trailer',
      dataIndex: 'trailer',
      render: (name, record, index) => {
        return <>{record.trailer}</>;
      },
      width: '24%',
      ellipsis: true,
    },
    {
      title: 'From',
      key: 'from',
      dataIndex: 'from',
      render: (name, record, index) => {
        return <>{record.from}</>;
      },
      width: '24%',
      ellipsis: true,
    },
    {
      title: 'To',
      key: 'to',
      dataIndex: 'to',
      render: (name, record, index) => {
        return <>{record.to}</>;
      },
      width: '24%',
      ellipsis: true,
    },
    {
      title: 'Start Odometer',
      key: 'startOdometr',
      dataIndex: 'startOdometr',
      render: (name, record, index) => {
        return <>{record.startOdometr}</>;
      },
      width: '29%',
      ellipsis: true,
    },
    {
      title: 'End Odometer',
      key: 'endOdometr',
      dataIndex: 'endOdometr',
      render: (name, record, index) => {
        return <>{record.endOdometr}</>;
      },
      width: '24%',
      ellipsis: true,
    },
    {
      title: 'Miles Today',
      key: 'miles',
      dataIndex: 'miles',
      render: (name, record, index) => {
        return <>{record.miles}</>;
      },
      width: '24%',
      ellipsis: true,
    },
    {
      title: 'Start EH',
      key: 'startEh',
      dataIndex: 'startEh',
      render: (name, record, index) => {
        return <>{record.startEh}</>;
      },
      width: '24%',
      ellipsis: true,
    },
    {
      title: 'End EH',
      key: 'endEh',
      dataIndex: 'endEh',
      render: (name, record, index) => {
        return <>{record.endEh}</>;
      },
      width: '24%',
      ellipsis: true,
    },
    {
      title: 'EH Today',
      key: 'ehtoday',
      dataIndex: 'ehtoday',
      render: (name, record, index) => {
        return <>{record.ehtoday}</>;
      },
      width: '29%',
      ellipsis: true,
    },
    {
      title: 'Shipping Docs',
      key: 'shipping',
      dataIndex: 'shipping',
      render: (name, record, index) => {
        return <>{record.shipping}</>;
      },
      width: '24%',
      ellipsis: true,
    },
    {
      title: 'Co-Driver',
      key: 'codriver',
      dataIndex: 'codriver',
      render: (name, record, index) => {
        return <>{record?.codriver ? record?.codriver : ''}</>;
      },
      width: '24%',
      ellipsis: true,
    },
  ];

  useLayoutEffect(() => {
    const day = dayjs(driverLogDate).valueOf();
    dispatch(
      getLogForm({
        queryParams: {
          id: driverLog?.id,
          timestamp: day,
        },
      }),
    );
  }, [logs]);

  useEffect(() => {
    const firstLog = logs?.[0];
    const lastLog = logs.length != 1 ? logs?.[logs.length - 1] : null;
    const lastLogHours = lastLog ? lastLog.duration : '';
    const summHours = `${lastLog?.duration && firstLog.duration ? lastLog?.duration + firstLog?.duration : ''}`;
    const data = {
      timestamp: driverConfigLog?.timestamp,
      vehicle: driverConfigLog?.vehicle?.identificator,
      vin: driverConfigLog?.vehicle?.id,
      eld: driverConfigLog?.unit?.device?.identificator,
      trailer: driverConfigLog?.trailer,
      from: firstLog?.location,
      to: lastLog?.location,
      startOdometr: firstLog?.total_miles,
      endOdometr: lastLog?.total_miles,
      startEh: firstLog?.duration,
      endEh: lastLogHours ? lastLogHours : '',
      codriver: driverConfigLog?.codriver
        ? driverConfigLog?.codriver?.first_name +
          ' ' +
          driverConfigLog?.codriver?.last_name +
          ' ' +
          `(${driverConfigLog?.codriver?.username})`
        : '',
      miles: `${lastLog ? firstLog?.total_miles - lastLog?.total_miles : ''}`,
      ehtoday: summHours,
      shipping: driverConfigLog?.shipping_doc,
    };

    setData([data]);
  }, [logs]);
  return (
    <>
      <Table
        columns={columns.slice(0, 5)}
        rowKey={(record) => record.id}
        dataSource={data}
        pagination={false}
        className="table-custom-form"
        bordered
      />
      <Table
        columns={columns.slice(5, 10)}
        rowKey={(record) => record.id}
        dataSource={data}
        pagination={false}
        className="table-custom-form-middle"
        bordered
      />
      <Table
        columns={columns.slice(10, 15)}
        rowKey={(record) => record.id}
        dataSource={data}
        pagination={false}
        className="table-custom-form-bottom"
        bordered
      />
    </>
  );
};
