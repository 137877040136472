import React from 'react';
import { Tabs } from 'antd';
import './logtab.scss';
import { LogTabelPanel } from '../LogTabelPanel';
import { LogTab } from '../LogFormTab';
import { LogTableTransaction } from '../LogTableTransaction';
import { LogTabelUnidentified } from '../LogTableUnidentified';
import { LogOriginalTab } from '../LogOriginalTab';
import DVIRTab from '../DVIRTab';
import { ChartDalabels } from './Chart/interface/ChartInterface';

export const LogTabs = ({
  datalabels,
  rowRefs,
}: {
  datalabels: ChartDalabels[];
  rowRefs: React.MutableRefObject<{
    [key: string]: HTMLTableRowElement | null;
  }>;
}) => {
  const items: any = [
    {
      key: '1',
      label: 'Records',
      children: <LogTabelPanel rowRefs={rowRefs} datalabels={datalabels} />,
    },
    {
      key: '2',
      label: 'Profile Form',
      children: <LogTab />,
    },
    {
      key: '3',
      label: 'Unidentified logs',
      children: <LogTabelUnidentified />,
    },
    {
      key: '4',
      label: 'View Original Logs',
      children: <LogOriginalTab />,
    },
    {
      key: '5',
      label: 'Transactions',
      children: <LogTableTransaction />,
    },
    {
      key: '6',
      label: 'DVIR',
      children: <DVIRTab />,
    },
  ];
  return (
    <div className="ubuntu log-tabs-container">
      <Tabs
        defaultActiveKey="1"
        items={items}
        tabBarStyle={{ border: 'none' }}
      />
    </div>
  );
};
