import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Row, Col, Select, Button, DatePicker, Space } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useTableParams } from '../../../hooks/useTableParams';
import dayjs from 'dayjs';
import { getOrderFromTableParams } from '../../../hooks/utils';
import { InputPageTitle } from '../../common/doubleinput/InputPageTitle';

import customParseFormat from 'dayjs/plugin/customParseFormat';
import { usePermissions } from '../../../hooks/usePermissions';
import { AllPermissionsType } from '../role/constant';
import { NoPermission } from '../../common/NoPermission';
import {
  getTransaction,
  transactionRolllBackReq,
} from '../../../actions/transaction';
import MainContainer from '../../layout/MainContainer';
import MainHeader from '../../header/MainHeader';

const { RangePicker } = DatePicker;

dayjs.extend(customParseFormat);

export const LogListTransaction: React.FC = () => {
  const dispatch = useDispatch();
  const { handleTableChange, tableParams, clearCustomFilter, setCustomFilter } =
    useTableParams({});
  const transaction = useSelector(
    (state: any) => state?.transaction?.allTransactions,
  );
  const loading = useSelector((state: any) => state.log.loading);
  const [logDateFilter, setLogDateFilter] = React.useState<any>('');
  const [logDateCreatedAtFilter, setLogDateCreatedAtFilter] =
    React.useState<any>('');

  const columns: ColumnsType<any> = [
    {
      title: 'Period',
      key: 'identificator_log',
      dataIndex: 'identificator',
      render: (name, record, index) => {
        return (
          <div style={{ marginLeft: 10 }}>
            {dayjs(record?.time_from * 1000).format('MM/DD/YYYY')} -{' '}
            {dayjs(record?.time_to * 1000).format('MM/DD/YYYY')}
          </div>
        );
      },
      width: '12%',
      ellipsis: true,
      filterDropdown: () => {
        return (
          <div style={{ padding: 10 }}>
            <RangePicker
              onChange={(v, d) => {
                setLogDateFilter(d);
              }}
            />
            <Button
              onClick={() => {
                setCustomFilter('period', logDateFilter);
              }}
            >
              Apply
            </Button>
          </div>
        );
      },
      filteredValue: tableParams?.filters?.period || null,
    },
    {
      title: 'Carrier',
      key: 'carrier',
      dataIndex: 'carrier',
      render: (name, record, index) => {
        return <div>{record?.unit?.carrier?.name}</div>;
      },
      width: '10%',
      ellipsis: true,
      filterDropdown: () => {
        return (
          <div style={{ padding: 10 }}>
            <div>
              <Select
                style={{ width: 200, marginBottom: 20 }}
                value={tableParams.filters?.carrier}
                onChange={(value) => {
                  setCustomFilter('user', value);
                }}
              >
                {[
                  { name: 'Carrier_1', id: '1' },
                  { name: 'Carrier_2', id: '2' },
                ]?.map((user: any) => {
                  return (
                    <Select.Option key={user.id} value={user.id}>
                      {user.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </div>
            <Button
              style={{ width: 80, height: 40 }}
              className="orange"
              onClick={() => {
                clearCustomFilter('user');
              }}
            >
              Reset
            </Button>
          </div>
        );
      },
      filteredValue: '',
    },
    {
      title: 'Driver',
      dataIndex: 'driver',
      key: 'driver',
      width: '10%',
      ellipsis: true,
      render: (value, record, index) => {
        return (
          <div className="ubuntu">
            {record?.driver?.first_name + ' ' + record?.driver?.last_name}
          </div>
        );
      },
      filterDropdown: () => {
        return (
          <div style={{ padding: 10 }}>
            <div>
              <Select
                style={{ width: 200, marginBottom: 20 }}
                value={tableParams.filters?.driver}
                onChange={(value) => {
                  setCustomFilter('driver', value);
                }}
              >
                {transaction?.map((value: any) => {
                  return (
                    <Select.Option
                      key={value.driver.id}
                      value={value.driver.id}
                    >
                      {value.driver.first_name} {value.driver.last_name}
                    </Select.Option>
                  );
                })}
              </Select>
            </div>
            <Button
              style={{ width: 80, height: 40 }}
              className="orange"
              onClick={() => {
                clearCustomFilter('user');
              }}
            >
              Reset
            </Button>
          </div>
        );
      },
      filteredValue: tableParams?.filters?.driver || null,
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
      width: '11%',
      ellipsis: true,
      render: (value, record, index) => {
        return (
          <div className="ubuntu" style={{ cursor: 'pointer' }}>
            {dayjs(record?.created_at).format('MM/DD/YYYY hh:mm A')}
          </div>
        );
      },
      filterDropdown: () => {
        return (
          <div style={{ padding: 10 }}>
            <RangePicker
              onChange={(v, d) => {
                setLogDateCreatedAtFilter(d);
              }}
            />
            <Button
              onClick={() => {
                setCustomFilter('created_at', logDateCreatedAtFilter);
              }}
            >
              Apply
            </Button>
          </div>
        );
      },
      filteredValue: tableParams?.filters?.created_at || null,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '6%',
      ellipsis: true,
      render: (value, record, index) => {
        return (
          <div className="ubuntu" style={{ cursor: 'pointer' }}>
            {record?.status_value}
          </div>
        );
      },
      filters: [
        { text: 'IsProgress', value: '1' },
        { text: 'Declined', value: '0' },
        { text: 'Finished', value: '2' },
        { text: 'ToApprove', value: '3' },
      ],
      filteredValue: tableParams?.filters?.status || null,
    },
    {
      title: 'Info',
      dataIndex: 'info',
      key: 'info',
      width: '4%',
      ellipsis: true,
      render: (value, record, index) => {
        return (
          <div className="ubuntu" style={{ cursor: 'pointer' }}>
            {record?.events?.length}
          </div>
        );
      },
    },

    {
      title: 'User',
      dataIndex: 'user',
      sortOrder: getOrderFromTableParams('user', tableParams),
      key: 'user',
      width: '10%',
      ellipsis: true,
      render: (value, record, index) => {
        return (
          <div className="ubuntu" style={{ cursor: 'pointer' }}>
            {record?.user?.first_name} {record?.user?.last_name}
          </div>
        );
      },
      filters: [
        { text: 'Piotr Kava', value: 'Piotr Kava' },
        {
          text: 'Roman Dintu',
          value: 'Roman Dintu',
        },
      ],
      filteredValue: tableParams?.filters?.user || null,
    },
    {
      title: 'Roll Back',
      dataIndex: 'total_hours',
      key: 'total_hours',
      width: '6%',
      ellipsis: true,
      render: (value, record, index) => {
        const click = () => {
          dispatch(
            transactionRolllBackReq({
              id: record.id,
            }),
          );
        };
        return (
          <div className="ubuntu" style={{ display: 'flex' }}>
            <button onClick={click} className="roll-back-button">
              Roll Back
            </button>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(
      getTransaction({
        queryParams: {
          with: ['unit', 'events', 'driver', 'user'],
        },
      }),
    );
  }, [tableParams]);

  const { checkPermission } = usePermissions();

  return (
    <>
      {checkPermission(AllPermissionsType.VEHICLE_LIST) ? (
        <>
          <MainContainer>
            <Row>
              <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
                <MainHeader titles={[{ text: 'Transactions' }]} />
              </Col>
            </Row>
            <div style={{ width: '100%' }} className="logs-table">
              <Table
                columns={columns}
                rowKey={(record) => record.id}
                dataSource={transaction}
                pagination={{
                  ...tableParams.pagination,
                  position: ['bottomCenter'],
                }}
                rowClassName={'table-row-custom'}
                loading={loading}
                onChange={handleTableChange}
                className="table-custom"
              />
            </div>
          </MainContainer>
        </>
      ) : (
        <NoPermission />
      )}
    </>
  );
};
