import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getCarrierReq, createCarrierReq } from '../../../actions/carrier';

import { Row, Col, Form, Button, Input, Spin, Popconfirm, Modal } from 'antd';
import { CommonInput } from '../../common/inputs';
import { carrierForm } from './carrier-form';
import { Graph } from '../../common/graph/Graph';
import { InputType } from '../../../constants/inputs';
import { usePermissions } from '../../../hooks/usePermissions';
import { AllPermissionsType } from '../role/constant';
import { NoPermission } from '../../common/NoPermission';
import MainContainer from '../../layout/MainContainer';
import { cleanObject } from '../../../utils/cleanObject';

export const CarrierCreatePage = () => {
  const [form] = Form.useForm();
  const params = useParams();
  const dispatch = useDispatch();
  const { loading, carrier } = useSelector((state: any) => state.carrier);
  const { user } = useSelector((state: any) => state.auth);
  const [fields, setFields] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [open, setOpen] = useState(false);
  // const [confirmLoading, setConfirmLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({
    name: null,

    usdot: null,
    phone: null,
    mcnumber: null,
    email: null,
    person: null,
    status: 1,
    notes: null,
    email_second: null,
    settings: {
      measurement_system: 2,
      dst: 1,
      first_day: 1,
      compliance_mode: 1,
      motion_treshold: 5,
      cargo_type: [1],
      restart: 2,
      rest_break: 1,
      short_haul: false,
      personal_conveyance: true,
      adverse_conditions: true,
      unlimited_documents: true,
      unlimited_trailers: true,
      yard_move: true,
      exempt_driver: false,
      exempt_driver_notice: false,
      period_starting_time: '00:00:00',
      motion_threshold: 5,
      hos_rules: 1,
      time_format: 1,
      date_format: 1,
    },
    terminals: [
      {
        name: '',
        country: 1,
        state: 1,
        area: '',
        address_index: '',
        tz: 1,
        number_street: '',
      },
    ],
  });
  const navigate = useNavigate();

  const handleSubmit = async () => {
    const values = form.getFieldsValue();
    const data = cleanObject({
      ...values,
      company: user.company.id,
      offices: [...user.offices].map((office) => office.id),
    });

    dispatch(
      createCarrierReq({
        values: data,
        onSuccess: () => {
          form.setFieldsValue(initialValues);
          navigate(-1);
        },
      }),
    );
    setOpen(false);
  };

  const changeValue = () => {
    let value: string = form.getFieldValue([
      'settings',
      'period_starting_time',
    ]);
    if (value) {
      value = value.replace(/\D/g, '');

      const hours = parseInt(value.slice(0, 2), 10);
      if (hours < 0 || hours > 24) {
        value = value.slice(0, 2);
      }
      const minutes = parseInt(value.slice(2, 4), 10);
      if (minutes < 0 || minutes > 59) {
        value = value.slice(0, 4);
      }
      const seconds = parseInt(value.slice(4, 6), 10);
      if (seconds < 0 || seconds > 59) {
        value = value.slice(0, 5);
      }

      value = value.slice(0, 6);
      value = value.replace(/(..)(?!$)/g, '$1:');
      form.setFieldValue(['settings', 'period_starting_time'], value);
    }
  };

  const { checkPermission } = usePermissions();
  return (
    <>
      {checkPermission(AllPermissionsType.CARRIER_CREATE) ? (
        <MainContainer half>
          <Row style={{ height: '100%' }}>
            {/* <Graph /> */}
            {loading ? (
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  minHeight: 600,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Spin />
              </div>
            ) : (
              <Col span={24}>
                <Form
                  form={form}
                  name="test"
                  onFinish={() => {
                    setOpen(true);
                  }}
                  onChange={changeValue}
                  initialValues={initialValues}
                >
                  {carrierForm({ create: true }).map(
                    (field: any, i: number) => {
                      if (field.type === InputType.ADD_DYNAMIC) {
                        return (
                          <CommonInput
                            currentIndex={currentIndex}
                            fields={fields}
                            key={i}
                            setCurrentIndex={setCurrentIndex}
                            {...field}
                            form={form}
                          />
                        );
                      }
                      return <CommonInput key={i} {...field} form={form} />;
                    },
                  )}
                  <Form.Item style={{ width: '100%', display: 'flex' }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="orange"
                      style={{ width: '85px', marginRight: 12 }}
                    >
                      Submit
                    </Button>
                    <Button
                      className="grey"
                      style={{ width: '85px', marginRight: 12 }}
                      onClick={() => {
                        form.setFieldsValue(initialValues);
                      }}
                    >
                      Cancel
                    </Button>
                  </Form.Item>
                </Form>
                <Modal
                  title="Confirm Save Changes"
                  open={open}
                  onOk={handleSubmit}
                  onCancel={() => setOpen(false)}
                  okText="Save"
                  cancelText="Cancel"
                >
                  Are you sure you want to save the changes made to the Carrier
                  Profile?
                  <div>
                    Any modifications you have made will be saved. This action
                    cannot be undone.
                  </div>
                </Modal>
              </Col>
            )}
          </Row>
        </MainContainer>
      ) : (
        <NoPermission />
      )}
    </>
  );
};
